import NoDataFound from "components/NoDataFound";
import StyledHtml from "components/StyledHtml";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";

const JobDetails = () => {
  const { application_details, isLoading } = useApplicationDetails();
  return (
    <div>
      <div className="text-2xl font-semibold mb-8">Job Details</div>
      {application_details?.job_description && (
        <StyledHtml
          htmlString={application_details?.job_description}
          id={
            application_details?.application_id ||
            application_details?.application_user_id
          }
        />
      )}
      {!isLoading && !application_details?.job_description && (
        <NoDataFound noDataText="No job description available" />
      )}
    </div>
  );
};

export default JobDetails;
