import toast from "react-hot-toast";
import moment from "moment";
import { GooglePlaceAddressComponentTypes } from "./types/general.types";

export const showMsg = (msg: string) => {
  toast.dismiss();
  toast.success(msg);
};
export const showError = (msg: string) => {
  toast.dismiss();
  toast.error(msg);
};

/**
 *
 * @param date : string
 * @param format : string
 * @returns This function converts UTC date to local date
 */
export const getLocalDate = (date: string, format?: string) => {
  if (format === "fromNow") {
    return moment.utc(date).local().fromNow();
  }
  return moment
    .utc(date)
    .local()
    .format(format || "MMM DD, YYYY");
};

export const isEmptyObject = (obj: Object) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const formatJobType = (jobType: string) => {
  return jobType.charAt(0).toUpperCase() + jobType.slice(1).toLowerCase();
};

export const placesToAddress = (places: {
  address_components: GooglePlaceAddressComponentTypes[];
}): {
  city: string;
  state: string;
  state_code: string;
  country_code: string;
  country: string;
} | null => {
  if (!places) return null;
  console.log("places: ", places);
  const { address_components } = places;

  const city = address_components.find(
    (component: GooglePlaceAddressComponentTypes) => {
      return component.types.includes("locality");
    }
  )?.long_name;
  const state = address_components.find(
    (component: GooglePlaceAddressComponentTypes) => {
      return component.types.includes("administrative_area_level_1");
    }
  )?.long_name;
  const state_code = address_components.find(
    (component: GooglePlaceAddressComponentTypes) => {
      return component.types.includes("administrative_area_level_1");
    }
  )?.short_name;
  const country = address_components.find(
    (component: GooglePlaceAddressComponentTypes) => {
      return component.types.includes("country");
    }
  )?.long_name;
  const country_code = address_components.find(
    (component: GooglePlaceAddressComponentTypes) => {
      return component.types.includes("country");
    }
  )?.short_name;

  return {
    city: city || "",
    state: state || "",
    state_code: state_code || "",
    country_code: country_code || "",
    country: country || "",
  };
};

export function urlify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a target="blank" href="' + url + '">' + url + "</a>";
  });
}

export function isValidDate(d: string) {
  return !isNaN(new Date(d?.replace(/-/g, "/")).getTime());
}

export const createChunks = (arr: any, size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export function parseCSVRow(row: any) {
  const values: any = [];
  let currentValue = "";
  let withinQuotes = false;

  for (let i = 0; i < row.length; i++) {
    const char = row[i];

    if (char === "," && !withinQuotes) {
      values.push(currentValue.trim());
      currentValue = "";
    } else if (char === '"') {
      withinQuotes = !withinQuotes;
    } else {
      currentValue += char;
    }
  }

  values.push(currentValue.trim());
  return values;
}
