import { useQuery } from "@tanstack/react-query";
import CustomTooltip from "components/CustomTooltip";
import SuggestedTaskLoader from "components/loaders/SuggestedTaskLoader";
import NoDataFound from "components/NoDataFound";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";
import { InterviewQuestionType } from "helpers/types/interviewQuestions.types";
import questions from "helpers/http/questions";
import { useAuth } from "context/AuthContext";

const Questions = () => {
  const { application_details } = useApplicationDetails();
  const { user } = useAuth();
  const { data, isLoading, refetch } = useQuery(
    ["get-interview-questions", application_details.application_user_id],
    () =>
      questions.getInterviewQuestions(application_details.application_user_id)
  );

  return (
    <div className="p-6 border border-solid border-lightgray rounded-xl">
      <header className="flex items-center gap-2 mb-5">
        <span className="text-lg font-semibold">Interview Questions</span>
        <CustomTooltip title="These AI generated interview questions will get you in tip-top shape for the real deal." />
      </header>

      <div className="listing md:h-[50vh] max-h-[50vh] overflow-y-auto overflow-x-hidden custom-scroll pt-1">
        {isLoading && (
          <SuggestedTaskLoader className="md:w-[300px] w-[200px]" />
        )}
        {!isLoading &&
          data?.data?.questionListResult.map(
            (question: InterviewQuestionType) => (
              <Question
                question={question}
                key={question.interview_question_id}
                refetch={refetch}
                application_user_id={application_details.application_user_id}
                is_admin={user?.is_admin}
              />
            )
          )}
        {!isLoading && !data?.data?.questionListResult?.length && (
          <NoDataFound noDataText="No Interview Questions found" />
        )}
      </div>
    </div>
  );
};

export default Questions;

const Question = ({
  question,
  refetch,
  application_user_id,
  is_admin,
}: {
  question: InterviewQuestionType;
  refetch: () => void;
  application_user_id: string;
  is_admin?: boolean;
}) => {
  return (
    <div className="flex gap-3 border border-solid border-lightgray rounded-lg mb-4 p-4">
      <div>
        <div className="text-sm text-[#1D1E1B]">{question.prompt}</div>
      </div>
    </div>
  );
};
