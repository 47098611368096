import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { useAuth } from "context/AuthContext";
import { CompanyType } from "helpers/types/general.types";
import { CONFIG } from "consts/CONFIG";

type Props = {
  url: string;
  className?: string;
  variant?: "small" | "large";
};

const CompanyLogo = ({ url, className, variant = "large" }: Props) => {
  const { settingsData } = useAuth();
  const imageUrl = useMemo(() => {
    const companyData = settingsData?.companyData;
    if (companyData) {
      const company = companyData.filter((i: CompanyType) =>
        url?.includes(i.company_slug)
      );
      return company[0]?.image || CONFIG.DEFAULT_COMPANY_ICON;
    }
  }, [settingsData?.companyData, url]);

  return (
    <img
      alt="company-logo"
      src={imageUrl}
      className={twMerge(
        variant === "large"
          ? "h-14 w-14 rounded-lg object-contain bg-blue-tint p-1"
          : "h-5 w-5 rounded-full object-contain",
        className
      )}
    />
  );
};
export default CompanyLogo;
