import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Grid } from "@mui/material";
import WrappedContent from "components/WrappedContent";
import Loader from "components/loaders/Loader";
import NoDataFound from "components/NoDataFound";
import EditNote from "./components/EditNote";
import DeleteNotePrompt from "components/DeleteNotePrompt";
import { NoteType } from "helpers/types/applications.types";
import notes from "helpers/http/notes";
import { getLocalDate } from "helpers/misc";
import { ReactComponent as NoteIcon } from "assets/images/note.svg";
import { useAuth } from "context/AuthContext";

const Notes = () => {
  const { user } = useAuth();
  const contactId = useMemo(() => {
    let id = "";
    if (window.location.pathname.includes("recommended")) {
      id = window.location.pathname.split("/")[3];
    } else {
      id = window.location.pathname.split("/")[2];
    }
    return id;
  }, []);

  const { data, isLoading } = useQuery(["get-contact-notes", contactId], () =>
    notes.getContactNotes(contactId)
  );
  return (
    <>
      <Grid container spacing={3} className="mb-5">
        {isLoading && (
          <Loader className="flex items-center justify-center flex-1 mt-3" />
        )}
        {!isLoading &&
          data?.data?.noteResult?.map((item: NoteType) => (
            <NoteCard
              key={item.note_id}
              item={item}
              is_admin={user?.is_admin}
            />
          ))}
      </Grid>
      {!isLoading && data?.data?.noteResult?.length === 0 && (
        <NoDataFound
          noDataText="No notes found"
          containerClassName="flex flex-col items-center justify-center flex-1 mt-16 mb-16 h-auto"
        />
      )}
    </>
  );
};

export default Notes;

const NoteCard = ({
  item,
  is_admin,
}: {
  item: NoteType;
  is_admin?: boolean;
}) => {
  return (
    <Grid item xs={12} md={6}>
      <WrappedContent className="flex gap-4 p-4 border border-solid border-lightgray rounded-xl h-full">
        <span>
          <NoteIcon />
        </span>
        <div className="flex-1">
          <div className="flex gap-2 justify-between">
            <div className="font-semibold capitalize">{item?.title}</div>
            {!is_admin && (
              <div className="flex items-start gap-1">
                <EditNote note_data={item} />
                <DeleteNotePrompt note_id={item.note_id} />
              </div>
            )}
          </div>
          <div className="text-sm text-darkGray mt-1">{item?.note}</div>
          <div className="text-xs dim-text mt-2">
            {getLocalDate(item?.date_created)}
          </div>
        </div>
      </WrappedContent>
    </Grid>
  );
};
