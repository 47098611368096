import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import { EditUserRequestType } from "helpers/types/user.types";

const user = {
  getUser() {
    return apiClient
      .post(API.MANAGE_USER, {
        action: "get_user",
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  editUser(payload: EditUserRequestType) {
    return apiClient
      .post(API.MANAGE_USER, {
        ...payload,
        action: "edit_user",
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteUser() {
    return apiClient
      .post(API.MANAGE_USER, {
        action: "delete_user",
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteExperience(work_experience_id: number) {
    return apiClient
      .post(API.MANAGE_EXPERIENCE, {
        action: "delete_work_experience",
        work_experience_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteEducation(education_id: number) {
    return apiClient
      .post(API.MANAGE_EDUCATION, {
        action: "delete_education",
        education_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default user;
