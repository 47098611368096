const setToken = (token: string) => {
  localStorage.setItem("token", token);
};
const getToken = () => {
  return localStorage.getItem("token");
};
const removeToken = () => {
  localStorage.removeItem("token");
};
const getProfileToken = () => {
  return localStorage.getItem("profile_token");
};
const removeProfileToken = () => {
  localStorage.removeItem("profile_token");
};
export { setToken, getToken, removeToken, getProfileToken, removeProfileToken };
