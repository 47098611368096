import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { Button } from "components/buttons";
import UIModal from "components/UIModal";
import Loader from "components/loaders/Loader";
import StyledHtml from "components/StyledHtml";
import network from "helpers/http/network";
import { ReactComponent as Repeat } from "assets/icons/repeat.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  contact_user_id: string;
};

const ReachoutMessageModal = ({ contact_user_id, isOpen, toggle }: Props) => {
  const [message, setMessage] = useState<string>("");
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["get-reachout-msg", contact_user_id],
    () =>
      network.getReachoutMessage({
        contact_user_id,
        message,
      }),
    {
      enabled: !!contact_user_id && isOpen,
    }
  );

  useEffect(() => {
    if (data?.data?.recommendedMessage)
      setMessage(data?.data?.recommendedMessage);
  }, [data?.data?.recommendedMessage]);

  const copyText = () => {
    navigator.clipboard.writeText(message);
    toast.dismiss();
    toast("Messsage copied!", {
      icon: "✏️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const regenerate = () => {
    refetch();
  };

  return (
    <UIModal
      show={isOpen}
      toggle={toggle}
      title={
        <div className="flex items-center gap-2">
          Recommended message
          {isLoading && <Loader size={20} />}
        </div>
      }
      subTitle="You can send this message through your LinkedIn or email to this person."
      maxWidth="md"
      footer={
        <div className="flex justify-end">
          <Button
            className="flex gap-2"
            onClick={copyText}
            disabled={isLoading || isRefetching}
          >
            <Copy className="fill-white" />
            Copy message
          </Button>
        </div>
      }
    >
      <div className="mt-3">
        <div className="border border-solid border-[#8D8E92] rounded p-5">
          <StyledHtml id={contact_user_id + "-contact"} htmlString={message} />
        </div>
        {!isRefetching ? (
          <Button
            variant="text"
            className="group flex items-center gap-2 text-primary-color cursor-pointer font-medium mt-4"
            onClick={regenerate}
          >
            <Repeat className="stroke-primary-color group-hover:stroke-lightblack" />
            Regenerate
          </Button>
        ) : (
          <span className="flex items-center mt-4 gap-2">
            <Loader size={20} className="mt-1" />
            Re-generating message...
          </span>
        )}
      </div>
    </UIModal>
  );
};

export default ReachoutMessageModal;
