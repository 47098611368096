import { Grid } from "@mui/material";
import Questions from "./components/Questions";

const InterviewQuetsions = () => {
  return (
    <div>
      <Grid container spacing={4} className="mt-0">
        <Grid item xs={12} md={12}>
          <Questions />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <SuggestedReachouts />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default InterviewQuetsions;
