import { twMerge } from "tailwind-merge";

type Props = {
  error?: string;
  className?: string;
};

const ErrorMessage = ({ error, className }: Props) => {
  if (!error) return null;
  return (
    <div className={twMerge("text-xs text-[#d32f2f] mt-1", className)}>
      {error}
    </div>
  );
};

export default ErrorMessage;
