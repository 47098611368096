import styled from "styled-components";

const Steps = styled.div`
  ol {
    margin-left: 0;
    padding-left: 0;
  }
  ol > li {
    counter-increment: item;
    list-style: none inside;
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    padding-top: 0.2rem;
  }
  ol > li:before {
    content: counter(item);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    background: var(--dark-blue-tint);
    color: var(--primary-color);
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    font-weight: 600;
    font-size: 0.875rem;
  }
`;

const ImportLinkedinContactsSteps = () => {
  return (
    <Steps>
      <ol>
        <li>
          <span>Log in to your LinkedIn account and Click the </span>
          <span>
            <img
              src="/images/me.png"
              alt="me"
              className="h-[22px] w-[22px] -mb-1"
            />{" "}
          </span>
          Me icon at the top of your LinkedIn homepage.
        </li>
        <li className="mt-1">Select Settings & Privacy from the dropdown.</li>
        <li className="mt-4">Click Data privacy on the left pane.</li>
        <li className="mt-4">
          Under the How LinkedIn uses your data section, click Get a copy of
          your data.
        </li>
        <li className="mt-1">
          Select Want something in particular? Select the data files you’re most
          interested in.
        </li>
        <li className="mt-3">Select Connections.</li>
        <li className="mt-3">Click Request archive.</li>
        <li className="mt-3">
          You'll receive an email to your Primary Email address which will
          include a link where you can download your list of connections.
        </li>
      </ol>
    </Steps>
  );
};

export default ImportLinkedinContactsSteps;
