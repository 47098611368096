import Applications from "./Applications";
import Networks from "./Networks";

const GLOBAL_SEARCH_ROUTES = [
  {
    id: "applications",
    label: "Applications",
    component: Applications,
  },
  {
    id: "networks",
    label: "Networks",
    component: Networks,
  },
];

export default GLOBAL_SEARCH_ROUTES;
