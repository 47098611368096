import { useEffect } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, IconButton } from "@mui/material";
import { Button, LoadingButton } from "components/buttons";
import UIModal from "components/UIModal";
import TextInput from "components/TextInput";
import CharactersCount from "components/CharactersCount";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";
import useToggle from "helpers/hooks/useToggle";
import notes from "helpers/http/notes";
import { CommonResponseType } from "helpers/types/response.types";
import {
  AddNoteRequestType,
  EditNoteRequestType,
} from "helpers/types/notes.types";
import { showError, showMsg } from "helpers/misc";
import { NoteType } from "helpers/types/applications.types";
import {
  NOTE_DESCRIPTION_ERROR,
  NOTE_MAX_CHAR_ERROR,
  NOTE_TITLE_ERROR,
} from "consts/ERRORS";
import INPUT_MAXLENGTH from "consts/INPUT_MAXLENGTH";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

type Props = {
  className?: string;
  addButtonClass?: string;
  note_data?: NoteType;
};

const DEFAULT_VALUES = {
  title: "",
  note: "",
};

const schema = yup
  .object({
    title: yup.string().required(NOTE_TITLE_ERROR),
    note: yup
      .string()
      .required(NOTE_DESCRIPTION_ERROR)
      .max(INPUT_MAXLENGTH.NOTE, NOTE_MAX_CHAR_ERROR),
  })
  .required();

const AddEditNote = ({ className, note_data, addButtonClass }: Props) => {
  const [isOpen, toggle] = useToggle();

  const queryClient = useQueryClient();
  const { application_details } = useApplicationDetails();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate } = useMutation<
    CommonResponseType,
    string,
    AddNoteRequestType
  >((data) => notes.addApplicationNote(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        queryClient.invalidateQueries([
          "get-application-notes",
          application_details?.application_user_id,
        ]);
        toggle();
        showMsg(res?.message);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const { isLoading: isEditing, mutate: editNote } = useMutation<
    CommonResponseType,
    string,
    EditNoteRequestType
  >((data) => notes.editNote(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        queryClient.invalidateQueries([
          "get-application-notes",
          application_details?.application_user_id,
        ]);
        toggle();
        showMsg(res?.message);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  useEffect(() => {
    if (isOpen) {
      if (note_data) {
        setValue("title", note_data?.title, {
          shouldValidate: true,
        });
        setValue("note", note_data?.note, {
          shouldValidate: true,
        });
      } else {
        reset();
      }
    }
  }, [isOpen, note_data, reset, setValue]);

  const onSubmit = (data: typeof DEFAULT_VALUES) => {
    if (!note_data) {
      const payload = {
        ...data,
        application_user_id: application_details?.application_user_id,
      };
      mutate(payload);
    } else {
      editNote({
        ...data,
        note_id: note_data?.note_id,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      {!note_data ? (
        <Button
          className={twMerge("flex items-center gap-2", addButtonClass)}
          onClick={toggle}
        >
          <span className="flex items-center">
            <PlusIcon className="stroke-white" />
          </span>
          <span>Add a note</span>
        </Button>
      ) : (
        <IconButton onClick={toggle} className="p-1">
          <EditIcon />
        </IconButton>
      )}

      <UIModal
        show={isOpen}
        toggle={toggle}
        title={note_data ? "Edit Note" : "Add Note"}
        maxWidth="md"
        footer={
          <div className="flex justify-end mt-4">
            <LoadingButton type="submit" loading={isLoading || isEditing}>
              Add
            </LoadingButton>
          </div>
        }
        papperProps={{
          component: "form",
          onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmit(onSubmit);
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextInput
              {...register("title")}
              label="Note title"
              placeholder="Enter title"
              helperText={errors?.title?.message?.toString()}
              error={!!errors?.title}
            />
          </Grid>
          <Grid item xs={12} md={12} className="relative">
            <TextInput
              {...register("note")}
              label="Description"
              placeholder="Enter description here"
              helperText={errors?.note?.message?.toString()}
              error={!!errors?.note}
              multiline
              rows={4}
              maxLength={null}
            />
            <CharactersCount
              className={classNames("", {
                "bottom-0": errors?.note,
                "-bottom-6": !errors?.note,
              })}
              maxCharacters={INPUT_MAXLENGTH.NOTE}
              charactersCount={watch("note")?.length}
            />
          </Grid>
          <div className="h-[30px] w-[30px]" />
        </Grid>
      </UIModal>
    </form>
  );
};

export default AddEditNote;
