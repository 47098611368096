export const ROUTES = {
  HOME: "/home",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:hash",
  PROFILE: "/profile",
  NETWORK: "/network",
  COMPLETE_PROFILE: "/complete-profile",
  APPLICATIONS: "/applications",
  APPLICATION_DETAILS: "/application-details/:id",
  RECOMMENDED_APPLICATION_DETAILS: "/application-details/recommended/:id",
  RECOMMENDED_NETWORK_DETAILS: "/network-details/recommended",
  NETWORK_DETAILS: "/network-details",
  NOT_FOUND: "/404",
  SWITCH_TO_ADMIN_MODE: "/admin-as-user",
  GLOBAL_SEARCH: "global-search",
};
