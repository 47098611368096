import { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import NoDataFound from "components/NoDataFound";
import LoadMoreButton from "components/LoadMoreButton";
import ContactCard from "./ContactCard";
import network from "helpers/http/network";
import {
  FilterTypes,
  RecommendedContactType,
} from "helpers/types/network.types";

const LIMIT = 10;

const RecommendedContacts = () => {
  const {
    filters,
    setLoading,
  }: { filters: FilterTypes; setLoading: (val: Boolean) => void } =
    useOutletContext();
  const {
    data,
    isFetching,
    isRefetching,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["get-recommended-contacts", filters],
    ({ pageParam = 1 }) => {
      return network.getRecommendedContacts({
        limit: LIMIT,
        page: pageParam,
        keyword: filters?.search,
        location: filters?.location,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentDataCount = allPages.reduce(
          (sum, item) => (sum += item?.data?.recommendContactData?.length),
          0
        );
        const hasNext =
          Number(currentDataCount) >= Number(LIMIT)
            ? Math.ceil(Number(currentDataCount) / LIMIT) + 1
            : false;
        return hasNext;
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x?.data?.recommendContactData),
        pageParams: data.pageParams,
      }),
    }
  );

  const recommendedData: RecommendedContactType[] = useMemo(
    () => data?.pages || [],
    [data?.pages]
  );

  useEffect(() => {
    if (!isFetchingNextPage) {
      setLoading(isRefetching || isLoading);
    }
  }, [isRefetching, setLoading, isFetchingNextPage, isLoading]);

  return (
    <>
      <Grid
        container
        className="mt-2"
        spacing={3}
        // onScroll={handleScroll}
      >
        {recommendedData?.length > 0 &&
          recommendedData?.map((item: RecommendedContactType) => (
            <Grid item md={6} xs={12} key={item.contact_id}>
              <ContactCard item={item} />
            </Grid>
          ))}

        {recommendedData?.length > 0 && (
          <LoadMoreButton
            hasNextPage={hasNextPage}
            isLoading={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            className="flex items-center justify-center mt-5 w-full"
          />
        )}
      </Grid>

      {!isFetching && recommendedData?.length === 0 && (
        <NoDataFound
          containerClassName="flex h-[72vh] justify-center"
          noDataText="No contacts found"
        />
      )}
    </>
  );
};

export default RecommendedContacts;
