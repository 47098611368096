import { twMerge } from "tailwind-merge";
import { ReactComponent as Loader } from "assets/images/loaders/suggested-task-loader.svg";

type Props = {
  className?: string;
};
const SuggestedTaskLoader = ({ className }: Props) => {
  return (
    <>
      {Array.from(Array(3).keys()).map((item) => (
        <div
          key={item}
          className="border border-solid border-lightgray p-5 pb-0 mb-4 rounded-lg"
        >
          <Loader className={twMerge("md:w-[400px] w-[200px]", className)} />
        </div>
      ))}
    </>
  );
};

export default SuggestedTaskLoader;
