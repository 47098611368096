export const APPLICATION_STATUS = [
  {
    label: "Applied",
    value: "APPLIED",
    badgeBg: "#E8EAFF",
    badgeColor: "#3454D1",
  },
  {
    label: "Interviewing",
    value: "INTERVIEWING",
    badgeBg: "#F8F0E5",
    badgeColor: "#BE6F26",
  },
  {
    label: "Interviewed",
    value: "INTERVIEWED",
    badgeBg: "#F4E5F8",
    badgeColor: "#712296",
  },
  {
    label: "Offered",
    value: "OFFERED",
    badgeBg: "#E5F8E5",
    badgeColor: "#22962E",
  },
  {
    label: "Rejected",
    value: "ARCHIVED_REJECTED",
    badgeBg: "gray",
    badgeColor: "gray",
  },
];
