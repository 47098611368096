import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import applications from "helpers/http/application";
import {
  GetApplicationsListResponseTypes,
  GetRecommendedResponseTypes,
} from "helpers/types/applications.types";
import { LocationType } from "helpers/types/general.types";

type FiltersType = {
  applicationFilter: string;
  jobType: string[];
  jobLocation: LocationType;
  search: string;
};

interface ContextType {
  selectedFilters: FiltersType;
  updateFilters: ({
    key,
    value,
  }: {
    key: string;
    value: string | string[] | LocationType;
  }) => void;
  children?: React.ReactNode;
  recommended: GetRecommendedResponseTypes[];
  isLoadingRecommended?: boolean;
  applicationsList: GetApplicationsListResponseTypes[];
  isLoadingApplications?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
}

const IntialFilterValue = {
  applicationFilter: "browse",
  jobType: [],
  jobLocation: {
    city: "",
    state: "",
    country: "",
    state_code: "",
    country_code: "",
  },
  search: "",
};

const LIMIT = 12;

const ApplicationsContext = React.createContext<ContextType>({
  selectedFilters: IntialFilterValue,
  updateFilters: () => {},
  recommended: [],
  applicationsList: [],
});

export const ApplicationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedFilters, setSelectedFilters] =
    React.useState<FiltersType>(IntialFilterValue);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const status = searchParams.get("status") || "browse";
    const jobType = searchParams.get("job_type")?.split(",") || [];
    const keyword = searchParams.get("keyword") || "";
    const city = searchParams.get("city") || "";
    const state = searchParams.get("state") || "";
    const state_code = searchParams.get("state_code") || "";
    const country = searchParams.get("country") || "";
    const country_code = searchParams.get("country_code") || "";
    const location = {
      city,
      state,
      country,
      state_code,
      country_code,
    };
    setSelectedFilters({
      applicationFilter: status,
      jobType,
      jobLocation: location,
      search: keyword,
    });
  }, [searchParams]);

  const updateFilters = useCallback(
    ({
      key,
      value,
    }: {
      key: string;
      value: string | string[] | LocationType;
    }) => {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [key]: value,
      }));
    },
    []
  );

  useEffect(() => {
    const jobType = !!selectedFilters.jobType?.length
      ? `&job_type=${selectedFilters.jobType.join(",")}`
      : "";
    const keyword = !!selectedFilters.search
      ? `&keyword=${selectedFilters.search}`
      : "";
    const city = !!selectedFilters.jobLocation?.city
      ? `&city=${selectedFilters.jobLocation?.city}`
      : "";
    const state = !!selectedFilters.jobLocation?.state
      ? `&state=${selectedFilters.jobLocation?.state}`
      : "";
    const state_code = !!selectedFilters.jobLocation?.state_code
      ? `&state_code=${selectedFilters.jobLocation?.state_code}`
      : "";
    const country = !!selectedFilters.jobLocation?.country
      ? `&country=${selectedFilters.jobLocation?.country}`
      : "";
    const country_code = !!selectedFilters.jobLocation?.country_code
      ? `&country_code=${selectedFilters.jobLocation?.country_code}`
      : "";

    const query = `?status=${selectedFilters.applicationFilter}${jobType}${keyword}${city}${state}${country}${state_code}${country_code}`;

    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      query;
    window.history.replaceState(null, "", newurl);
  }, [selectedFilters]);

  const {
    data: recommendedResponse,
    isFetching: isLoadingRecommended,
    hasNextPage: hasMoreRecommended,
    fetchNextPage: fetchNextRecommended,
    isFetchingNextPage: isFetchingNextRecommended,
  } = useInfiniteQuery(
    ["get-recommended-aplications", selectedFilters],
    ({ pageParam = 1 }) => {
      return applications.getRecommendedApplications({
        job_type: selectedFilters.jobType,
        keyword: selectedFilters.search,
        location: selectedFilters.jobLocation,
        limit: LIMIT,
        page: pageParam,
      });
    },

    {
      enabled: selectedFilters.applicationFilter === "browse",
      getNextPageParam: (lastPage, allPages) => {
        const currentDataCount = allPages.reduce(
          (sum, item) => (sum += item?.data?.recommendAppData?.length),
          0
        );
        const total = allPages[0]?.data?.total;
        const currentCount = lastPage?.data?.recommendAppData?.length;

        const hasNext =
          Number(currentDataCount) < Number(total) && currentCount > 0
            ? Math.ceil(Number(currentDataCount) / LIMIT) + 1
            : false;
        console.log("currentCount: ", currentCount);
        // const hasNext =
        //   Number(currentDataCount) >= Number(LIMIT)
        //     ? Math.ceil(Number(currentDataCount) / LIMIT) + 1
        //     : false;
        return hasNext;
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x?.data?.recommendAppData),
        pageParams: data.pageParams,
      }),
    }
  );

  const recommended = useMemo(
    () => recommendedResponse?.pages || [],
    [recommendedResponse?.pages]
  );

  const {
    data: applicationListResponse,
    isFetching: isLoadingApplications,
    hasNextPage: hasMoreApplications,
    fetchNextPage: fetchNextApplications,
    isFetchingNextPage: isFetchingNextApplications,
  } = useInfiniteQuery(
    ["get-applications-list", selectedFilters?.applicationFilter],
    ({ pageParam = 1 }) => {
      return applications.getAppliacationsList({
        status: selectedFilters.applicationFilter,
        page: pageParam,
        limit: LIMIT,
      });
    },
    {
      enabled: selectedFilters.applicationFilter !== "browse",
      getNextPageParam: (lastPage, allPages) => {
        const currentDataCount = allPages.reduce(
          (sum, item) => (sum += item?.data?.applicationData?.length),
          0
        );
        const total = allPages[0]?.data?.total;
        const hasNext =
          Number(currentDataCount) < Number(total)
            ? Math.ceil(Number(currentDataCount) / LIMIT) + 1
            : false;
        return hasNext;
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x?.data?.applicationData),
        pageParams: data.pageParams,
      }),
    }
  );

  const applicationsList = useMemo(
    () => applicationListResponse?.pages || [],
    [applicationListResponse?.pages]
  );

  return (
    <ApplicationsContext.Provider
      value={{
        selectedFilters,
        updateFilters,
        recommended,
        isLoadingRecommended,
        applicationsList,
        isLoadingApplications,
        hasNextPage:
          selectedFilters.applicationFilter === "browse"
            ? hasMoreRecommended
            : hasMoreApplications,
        fetchNextPage:
          selectedFilters.applicationFilter === "browse"
            ? fetchNextRecommended
            : fetchNextApplications,
        isFetchingNextPage:
          selectedFilters.applicationFilter === "browse"
            ? isFetchingNextRecommended
            : isFetchingNextApplications,
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};

export const useApplications = () => {
  if (!React.useContext(ApplicationsContext)) {
    throw new Error(
      "useApplications must be used within a ApplicationsProvider"
    );
  }
  return React.useContext(ApplicationsContext);
};
