import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import {
  AddApplicationRequestTypes,
  GetRecommendedRequestTypes,
  SaveUnsaveApplicationTypes,
  UpdateApplicationStatusTypes,
} from "helpers/types/applications.types";

const applications = {
  getListCounts() {
    return apiClient
      .post(API.MANAGE_APPLICATION, {
        action: "get_list_count",
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getRecommendedApplications(payload: GetRecommendedRequestTypes) {
    let data = {
      ...payload,
    };
    if (!payload.scrapId) {
      delete data.scrapId;
    }
    return apiClient
      .post(API.MANAGE_RECOMMENDED_APPLICATION, {
        action: "get_recommend_application",
        // action: "get_recommend_application_v2",
        ...data,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getAppliacationsList(payload: {
    status: string;
    page: number;
    limit: number;
  }) {
    return apiClient
      .post(API.GET_APPLICATIONS_LIST, payload)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  saveUnsaveApplication(payload: SaveUnsaveApplicationTypes) {
    return apiClient
      .post(API.MANAGE_APPLICATION, {
        action: "clone_application",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  addApplication(payload: AddApplicationRequestTypes) {
    return apiClient
      .post(API.MANAGE_APPLICATION, {
        action: "add_application",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getRecommendedApplicationDetails(application_id: string) {
    return apiClient
      .post(API.MANAGE_RECOMMENDED_APPLICATION, {
        action: "get_details",
        application_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getApplicationDetails(application_id: string) {
    return apiClient
      .post(API.MANAGE_APPLICATION, {
        action: "get_details",
        application_user_id: application_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteApplication(application_id: string) {
    return apiClient
      .post(API.MANAGE_APPLICATION, {
        action: "delete_application",
        application_user_id: application_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  changeStatus(payload: UpdateApplicationStatusTypes) {
    return apiClient
      .post(API.MANAGE_APPLICATION, {
        action: "change_stage",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default applications;
