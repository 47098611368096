import JobDetails from "./JobDetails";
import Notes from "./Notes";
import Progress from "./Progress";
import InterviewQuestions from "./InterviewQuestions";

const APPLICATION_DETAILS_ROUTES = [
  {
    id: "job-details",
    label: "Job Details",
    component: JobDetails,
  },
  {
    id: "progress",
    label: "Progress",
    component: Progress,
  },
  {
    id: "interview-questions",
    label: "Interview Questions",
    component: InterviewQuestions,
  },
  {
    id: "notes",
    label: "Notes",
    component: Notes,
  },
];

export default APPLICATION_DETAILS_ROUTES;
