import { EditSuggestedActivityRequestType } from "./../types/network.types";
import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import {
  AddActivityRequestType,
  AddContactRequestType,
  GetContactsRequestTypes,
} from "helpers/types/network.types";

const network = {
  getRecommendedContacts(payload: GetContactsRequestTypes) {
    return apiClient
      .post(API.MANAGE_RECOMMENDED_CONTACT, {
        action: "get_recommend_contact",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getContacts(payload: GetContactsRequestTypes) {
    return apiClient
      .post(API.GET_NETWORK_CONTACTS, {
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  addManualContact(payload: AddContactRequestType) {
    return apiClient
      .post(API.MANAGE_NETWORK_CONTACT, {
        action: "add_manually_contact",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getRecommendedContactDetails(contact_id: string) {
    return apiClient
      .post(API.MANAGE_RECOMMENDED_CONTACT, {
        action: "get_details",
        contact_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getContactDetails(contact_user_id: string) {
    return apiClient
      .post(API.MANAGE_NETWORK_CONTACT, {
        action: "get_details",
        contact_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getSuggestedActivites(payload: {
    contact_id?: string;
    contact_user_id?: string;
  }) {
    let payloadToSend: {
      action: string;
      contact_id?: string;
      contact_user_id?: string;
    } = {
      action: "get_suggested_activity",
    };
    if (payload.contact_id) {
      payloadToSend.contact_id = payload.contact_id;
    } else {
      payloadToSend.contact_user_id = payload.contact_user_id;
    }
    return apiClient
      .post(API.MANAGE_CONTACT_ACTIVITY, payloadToSend)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  editSuggestion(payload: EditSuggestedActivityRequestType) {
    return apiClient
      .post(API.EDIT_SUGGESTED_NETWORK_ACTIVITY, payload)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteContact(contact_user_id: string) {
    return apiClient
      .post(API.MANAGE_NETWORK_CONTACT, {
        action: "delete_contact",
        contact_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  cloneContact(contact_id: string) {
    return apiClient
      .post(API.MANAGE_NETWORK_CONTACT, {
        action: "clone_contact",
        contact_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getNetworkActivities(contact_user_id: string) {
    return apiClient
      .post(API.MANAGE_CONTACT_ACTIVITY, {
        action: "get_activity",
        contact_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  addNetworkActivity(payload: AddActivityRequestType) {
    return apiClient
      .post(API.MANAGE_CONTACT_ACTIVITY, {
        action: "add_activity",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getReachoutMessage(payload: { contact_user_id: string; message: string }) {
    return apiClient
      .post(API.MANAGE_CONTACTS, {
        action: "get_reach_out_message",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default network;
