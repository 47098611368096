import { useState } from "react";
import classNames from "classnames";
import { useOutletContext } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "components/buttons";
import CustomTooltip from "components/CustomTooltip";
import NoDataFound from "components/NoDataFound";
import Loader from "components/loaders/Loader";
import network from "helpers/http/network";
import {
  EditSuggestedActivityRequestType,
  NetworkActivityQuestionTypes,
  SuggestedActivityType,
  UpdateNetworkStatusResponseType,
} from "helpers/types/network.types";
import { showError } from "helpers/misc";
import { CommonResponseType } from "helpers/types/response.types";
import { ReactComponent as WhiteCheck } from "assets/icons/blue-check.svg";

const SuggestedActivities = () => {
  const {
    isRecommended,
    contactId,
    questionData,
    suggested_questions,
  }: {
    isRecommended: boolean;
    contactId: string;
    questionData: NetworkActivityQuestionTypes;
    suggested_questions: NetworkActivityQuestionTypes[];
  } = useOutletContext();
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(
    ["get-suggested-activities", contactId],
    () =>
      window.location.pathname.includes("recommended")
        ? network.getSuggestedActivites({
            contact_id: contactId,
          })
        : network.getSuggestedActivites({
            contact_user_id: contactId,
          }),
    {
      enabled: !!contactId,
    }
  );

  const { isLoading: isCloning, mutate } = useMutation<
    UpdateNetworkStatusResponseType,
    string,
    string
  >((data) => network.cloneContact(data), {
    onSuccess: async (res: UpdateNetworkStatusResponseType) => {
      if (res?.status) {
        if (res?.data?.contact_user_id) {
          editSuggestion({
            contact_user_id: res?.data?.contact_user_id,
            answer: selectedAnswer,
          });
        }
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const { isLoading: isEditing, mutate: mutateToEditSuggestion } = useMutation<
    CommonResponseType,
    string,
    EditSuggestedActivityRequestType
  >((data) => network.editSuggestion(data), {
    onSuccess: async (res: CommonResponseType, variables) => {
      if (res?.status) {
        if (isRecommended) {
          window.location.replace(
            `/network-details/${variables.contact_user_id}`
          );
        } else {
          refetch();
          queryClient.invalidateQueries([
            "get-network-details",
            variables.contact_user_id,
          ]);
        }
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const editSuggestion = (payload: EditSuggestedActivityRequestType) => {
    mutateToEditSuggestion(payload);
  };

  const answerActivityQuestion = (answer: string) => () => {
    setSelectedAnswer(answer);
    if (isRecommended) {
      mutate(contactId);
    } else {
      editSuggestion({
        contact_user_id: contactId,
        answer,
      });
    }
  };

  return (
    <div className="p-6 border border-solid border-lightgray rounded-xl">
      <header className="flex items-center gap-2 mb-5">
        <span className="text-lg font-semibold">Suggested Activities</span>
        <CustomTooltip title="Immerse yourself in these smartly selected activities! Each one is AI-generated to help you build a rapport and establish a deeper connection with this individual." />
      </header>

      {isLoading && <Loader className="flex items-center justify-center" />}
      {!isLoading && (
        <div className="flex md:flex-row flex-col gap-3">
          <div
            className={classNames(
              "listing max-h-[50vh] overflow-y-auto overflow-x-hidden custom-scroll pt-1 flex flex-col gap-4",
              {
                "md:w-[60%]": !!questionData?.question,
                "md:w-full": !questionData?.question,
              }
            )}
          >
            {data?.data.map((activity: SuggestedActivityType) => (
              <div
                className="border border-solid border-lightgray rounded-lg p-4"
                key={activity?.title}
              >
                <div className="text-lg font-semibold lightblack">
                  {activity.title}
                </div>
                <div className="text-sm text-darkGray mt-2">
                  {activity.description}
                </div>
              </div>
            ))}

            {suggested_questions?.map(
              (activity: NetworkActivityQuestionTypes) => (
                <div
                  className="border border-solid border-lightgray rounded-lg p-4 flex items-start gap-2 opacity-70"
                  key={activity?.question_id}
                >
                  <div className="h-6 w-6 mt-[2px] rounded-full border border-solid border-primary-color bg-primary-color flex justify-center items-center">
                    <WhiteCheck stroke="white" className="w-4" />
                  </div>
                  <div>
                    <div className="text-lg font-semibold lightblack">
                      {activity.question}
                    </div>
                    <div className="text-sm text-darkGray mt-2">
                      {activity.message}
                    </div>
                  </div>
                </div>
              )
            )}

            {!data?.data?.length && (
              <NoDataFound
                noDataText="No suggested activities found"
                containerClassName="flex items-center justify-center w-full"
              />
            )}
          </div>

          {!!questionData?.question && (
            <div className="flex items-center justify-between bg-blue-tint p-4 mt-1 rounded-lg gap-3 md:w-[40%] h-fit">
              <div className="font-medium lightblack">
                {questionData?.question}
              </div>
              {questionData?.answer?.map((answer: string) => (
                <span>
                  <LoadingButton
                    className="py-1"
                    loading={
                      (isCloning || isEditing) && selectedAnswer === answer
                    }
                    onClick={answerActivityQuestion(answer)}
                  >
                    {answer}
                  </LoadingButton>
                </span>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SuggestedActivities;

// suggested_questions
