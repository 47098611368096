import { Grid } from "@mui/material";
import ApplicationCard from "components/applications/ApplicationCard";
import LoadMoreButton from "components/LoadMoreButton";
import NoDataFound from "components/NoDataFound";
import { useAuth } from "context/AuthContext";

const Applications = () => {
  const { globalSearchResultsData, globalSearch } = useAuth();
  return (
    <>
      <Grid container spacing={2} className="mt-3">
        {globalSearchResultsData?.data?.map((item: any) => (
          <Grid
            item
            xs={12}
            md={6}
            sm={6}
            lg={4}
            key={item.application_user_id}
          >
            <ApplicationCard type="global-search" item={item} />
          </Grid>
        ))}

        {!!globalSearchResultsData?.data?.length && (
          <LoadMoreButton
            hasNextPage={globalSearchResultsData?.hasMore}
            isLoading={Boolean(globalSearchResultsData?.isFetchingNext)}
            fetchNextPage={globalSearchResultsData?.fetchNext}
            className="flex items-center justify-center mt-5 w-full"
          />
        )}
      </Grid>

      {!globalSearchResultsData?.isLoading &&
        !globalSearchResultsData?.data?.length && (
          <NoDataFound
            noDataText={`No applications found for '${globalSearch}'`}
            containerClassName="flex flex-col items-center justify-center w-full h-[72vh]"
          />
        )}
    </>
  );
};

export default Applications;
