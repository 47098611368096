import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import styled from "styled-components";
import { Button } from "./buttons";
import INPUT_MAXLENGTH from "consts/INPUT_MAXLENGTH";
import { ReactComponent as Search } from "assets/icons/search.svg";

const Input = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiInputBase-root {
    padding-right: 0;
    border: 1px solid #d9d9e7;
    height: 50px;
    max-width: 100%;
    border-radius: 0.5rem;
    border-radius: 14px;
  }
  &:hover {
    .MuiInputBase-root {
      border: 1px solid #b4b4b4;
      color: #444;
      ::placeholder {
        color: #444;
      }
    }
  }
  &:focus-within {
    .MuiInputBase-root {
      border: 1px solid #b4b4b4;
    }
  }
  .MuiFormHelperText-root {
    margin: 0.25rem 0 0 0;
  }
  /* &:input {
    &:-webkit-autofill {
      transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s";
    }
    &:-webkit-autofill:focus {
      transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s";
    }
    &:-webkit-autofill:hover {
      transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s";
    }
  } */
`;

type Props = TextFieldProps & {
  label?: string;
  type?: string;
  maxLength?: number | null;
  className?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  showSearchButton?: boolean;
  isLoading?: boolean;
};

const SearchBox = (
  {
    label,
    type = "text",
    maxLength = INPUT_MAXLENGTH.DEFAULT,
    className,
    startIcon = <Search />,
    showSearchButton,
    isLoading,
    endIcon,
    ...props
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div className={className}>
      <Input
        {...props}
        autoComplete="off"
        inputRef={ref}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon
            ? endIcon
            : showSearchButton && (
                <Button
                  type="submit"
                  className="rounded-none rounded-r-xl hover:transform-none hover:shadow-none h-full transition ease-in-out duration-300"
                  disabled={isLoading}
                >
                  Search
                </Button>
              ),
        }}
      />
    </div>
  );
};

export default React.forwardRef(SearchBox);
