import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { Button } from "components/buttons";
import UIModal from "components/UIModal";
import Loader from "components/loaders/Loader";
import StyledHtml from "components/StyledHtml";
import useToggle from "helpers/hooks/useToggle";
import suggesteds from "helpers/http/suggesteds";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";
import { ReactComponent as Repeat } from "assets/icons/repeat.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";

type Props = {
  application_user_id: string;
  suggested_tasks_id: number;
  cta: string;
};

const SuggestedMsg = ({
  application_user_id,
  suggested_tasks_id,
  cta,
}: Props) => {
  const { application_details } = useApplicationDetails();
  const [isOpen, toggle] = useToggle();

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["get-suggested-msg", suggested_tasks_id],
    () =>
      suggesteds.regenerateSuggestedTaskMsg({
        suggested_tasks_id,
        application_user_id,
      }),
    {
      enabled: !!suggested_tasks_id && isOpen,
    }
  );

  const copyText = () => {
    navigator.clipboard.writeText(data?.data?.recommendedMessage);
    toast.dismiss();
    toast("Messsage copied!", {
      icon: "✏️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const regenerate = () => {
    refetch();
  };

  return (
    <>
      <div
        className="text-primary-color font-medium text-sm mt-1 cursor-pointer"
        onClick={toggle}
      >
        {cta}
      </div>
      <UIModal
        show={isOpen}
        toggle={toggle}
        title={
          <div className="flex items-center gap-2">
            Recommended message
            {isLoading && <Loader size={20} />}
          </div>
        }
        subTitle={`Below is the AI generated follow up message for ${application_details.company_name}.`}
        maxWidth="md"
        footer={
          <div className="flex justify-end">
            <Button
              className="flex gap-2"
              onClick={copyText}
              disabled={isLoading || isRefetching}
            >
              <Copy className="fill-white" />
              Copy message
            </Button>
          </div>
        }
      >
        <div className="mt-3">
          <div className="border border-solid border-[#8D8E92] rounded p-5">
            {/* {data?.data?.recommendedMessage} */}
            <StyledHtml
              id={suggested_tasks_id + "-msg"}
              htmlString={data?.data?.recommendedMessage}
            />
          </div>
          {!isRefetching ? (
            <Button
              variant="text"
              className="group flex items-center gap-2 text-primary-color cursor-pointer font-medium mt-4"
              onClick={regenerate}
            >
              <Repeat className="stroke-primary-color group-hover:stroke-lightblack" />
              Regenerate
            </Button>
          ) : (
            <span className="flex items-center mt-4 gap-2">
              <Loader size={20} className="mt-1" />
              Re-generating message...
            </span>
          )}
        </div>
      </UIModal>
    </>
  );
};

export default SuggestedMsg;
