import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const RegisterPage = lazy(() => import("modules/register"));
export default function Register() {
  return (
    <SuspenseWrapper>
      <RegisterPage />
    </SuspenseWrapper>
  );
}
