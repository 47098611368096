import React from "react";
import axios from "axios";
import { twMerge } from "tailwind-merge";
import { FileUploader } from "react-drag-drop-files";
import { CircularProgress } from "@mui/material";
import { myTheme } from "styles/theme";
import { showError } from "helpers/misc";
import general from "helpers/http/general";

type Props = {
  onChange?: (data: { url: string; file?: File }) => void;
  folderName?: string;
  children?: React.ReactNode;
  contentClass?: string;
  allowedFileTypes?: string[];
  onFileSelect?: (file: File) => void;
};

function UploadToAWS({
  onChange,
  folderName = "user",
  children,
  contentClass,
  allowedFileTypes = ["JPEG", "PNG", "GIF", "JPG", "WEBP"],
  onFileSelect,
}: Props) {
  const [loading, setLoading] = React.useState(false);
  const handleChange = (file: File) => {
    if (onFileSelect) {
      onFileSelect(file);
      return;
    }
    const fileSize = file.size / 1024 / 1024; // Filesize in mb
    if (fileSize > 100) {
      showError("File size should not be more than 100 MB");
      return;
    }
    setLoading(true);
    general
      .generateAwsUrl({
        folder: folderName,
        file_name: file.name,
        content_type: file.type,
      })
      .then((res) => {
        const { uploadURL } = res;
        const contentType = file.type;
        console.log("res: ", res);
        axios
          .put(uploadURL, file, {
            headers: { "Content-Type": contentType },
          })
          .then(() => {
            setLoading(false);
            const url = uploadURL.split("?Content-Type=")[0];
            onChange &&
              onChange({
                url,
                file,
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            showError("Error uploading image");
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showError("Error uploading image");
      });
  };

  const onTypeError = () => {
    showError(`Only ${allowedFileTypes.join(", ")} files are allowed.`);
  };
  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={allowedFileTypes}
      onTypeError={onTypeError}
    >
      <div
        className={twMerge(
          "relative hover:brightness-90 transition ease-in-out duration-300",
          contentClass
        )}
      >
        {loading ? (
          <div className="absolute w-full h-full brightness-90">
            <CircularProgress
              className="absolute m-auto inset-0 stroke-red z-10"
              sx={{ color: myTheme.colors.primary }}
            />
          </div>
        ) : null}
        {children}
      </div>
    </FileUploader>
  );
}

export default UploadToAWS;
