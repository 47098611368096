import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import applications from "helpers/http/application";

interface ContextType {
  application_details: any;
  isLoading?: boolean;
  refetch: () => void;
}

const initialValues = {
  application_details: {
    data: "hello",
  },
  isLoading: false,
  refetch: () => {},
};

const ApplicationsDetailsContext =
  React.createContext<ContextType>(initialValues);

export const ApplicationDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isRecommended] = useState<boolean>(
    Boolean(window.location.pathname.includes("recommended"))
  );
  const [applicationId, setApplicationId] = useState<string>("");

  useEffect(() => {
    let appId = "";
    if (window.location.pathname.includes("recommended")) {
      appId = window.location.pathname.split("/")[3];
    } else {
      appId = window.location.pathname.split("/")[2];
    }
    setApplicationId(appId);
  }, []);

  const {
    data: recommendedApplicationResponse,
    isLoading: isLoadingRecommendedDetails,
    refetch: refetchRecommendedDetails,
  } = useQuery(
    ["get-recommended-application-details", applicationId],
    () => applications.getRecommendedApplicationDetails(applicationId),
    {
      enabled: isRecommended && !!applicationId,
    }
  );

  const {
    data: applicationDetailsResponse,
    isLoading: isLoadingApplicationDetails,
    refetch: refetchApplicationDetails,
  } = useQuery(
    ["get-application-details", applicationId],
    () => applications.getApplicationDetails(applicationId),
    {
      enabled: !isRecommended && !!applicationId,
    }
  );

  const recommendedAppDetails = useMemo(() => {
    return recommendedApplicationResponse?.data?.applicationData
      ? JSON.parse(
          JSON.stringify(recommendedApplicationResponse?.data?.applicationData)
        )
      : null;
  }, [recommendedApplicationResponse?.data?.applicationData]);

  const applicationsDetails = useMemo(() => {
    return applicationDetailsResponse?.data?.applicationData
      ? JSON.parse(
          JSON.stringify(applicationDetailsResponse?.data?.applicationData)
        )
      : null;
  }, [applicationDetailsResponse?.data?.applicationData]);

  return (
    <ApplicationsDetailsContext.Provider
      value={{
        application_details: isRecommended
          ? recommendedAppDetails
          : applicationsDetails,
        isLoading: isRecommended
          ? isLoadingRecommendedDetails
          : isLoadingApplicationDetails,
        refetch: isRecommended
          ? refetchRecommendedDetails
          : refetchApplicationDetails,
      }}
    >
      {children}
    </ApplicationsDetailsContext.Provider>
  );
};

export const useApplicationDetails = () => {
  if (!React.useContext(ApplicationsDetailsContext)) {
    throw new Error(
      "useApplicationDetails must be used within a ApplicationDetailsProvider"
    );
  }
  return React.useContext(ApplicationsDetailsContext);
};
