import { useMutation, useQuery } from "@tanstack/react-query";
import { IconButton } from "@mui/material";
import CustomTooltip from "components/CustomTooltip";
import SuggestedTaskLoader from "components/loaders/SuggestedTaskLoader";
import Loader from "components/loaders/Loader";
import NoDataFound from "components/NoDataFound";
import SuggestedMsg from "./SuggestedMsg";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";
import { TaskType } from "helpers/types/tasks.types";
import suggestedTasks from "helpers/http/suggesteds";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { CommonResponseType } from "helpers/types/response.types";
import { showError, showMsg } from "helpers/misc";
import { EditSuggestedTaskTypes } from "helpers/types/suggested.types";
import { useAuth } from "context/AuthContext";
import { ReactComponent as WhiteCheck } from "assets/icons/blue-check.svg";

const SuggestedTasks = () => {
  const { application_details } = useApplicationDetails();
  const { user } = useAuth();
  const { data, isLoading, refetch } = useQuery(
    ["get-suggested-tasks", application_details.application_user_id],
    () =>
      suggestedTasks.getSuggestedTasks(application_details.application_user_id)
  );

  return (
    <div className="p-6 border border-solid border-lightgray rounded-xl">
      <header className="flex items-center gap-2 mb-5">
        <span className="text-lg font-semibold">Suggested Tasks</span>
        <CustomTooltip title="Step into the future with these AI-crafted tasks! They're tailored specifically to boost your journey towards bagging this dream job." />
      </header>

      <div className="listing md:h-[50vh] max-h-[50vh] overflow-y-auto overflow-x-hidden custom-scroll pt-1">
        {isLoading && (
          <SuggestedTaskLoader className="md:w-[300px] w-[200px]" />
        )}
        {!isLoading &&
          data?.data?.taskListResult.map((task: TaskType) => (
            <Task
              task={task}
              key={task.suggested_tasks_id}
              refetch={refetch}
              application_user_id={application_details.application_user_id}
              is_admin={user?.is_admin}
            />
          ))}
        {!isLoading && !data?.data?.taskListResult?.length && (
          <NoDataFound noDataText="No suggested tasks found" />
        )}
      </div>
    </div>
  );
};

export default SuggestedTasks;

const Task = ({
  task,
  refetch,
  application_user_id,
  is_admin,
}: {
  task: TaskType;
  refetch: () => void;
  application_user_id: string;
  is_admin?: boolean;
}) => {
  const { isLoading: isDeleting, mutate: deleteTask } = useMutation<
    CommonResponseType,
    string,
    EditSuggestedTaskTypes
  >((data) => suggestedTasks.deleteSuggestedTask(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        refetch();
        showMsg(res?.message);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const { isLoading: isEditing, mutate: editTask } = useMutation<
    CommonResponseType,
    string,
    EditSuggestedTaskTypes
  >((data) => suggestedTasks.editSuggestedTask(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        refetch();
        showMsg(res?.message);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const onDelete = () => {
    deleteTask({
      suggested_tasks_id: task.suggested_tasks_id,
      application_user_id: application_user_id,
    });
  };

  const onCheck = () => {
    editTask({
      suggested_tasks_id: task.suggested_tasks_id,
      application_user_id: application_user_id,
    });
  };

  return (
    <div className="flex gap-3 border border-solid border-lightgray rounded-lg mb-4 p-4">
      {!is_admin && (
        <span>
          <CheckButton
            onCheck={onCheck}
            isLoading={isEditing}
            checked={Boolean(task.is_completed)}
          />
        </span>
      )}
      <div>
        <div className="font-semibold text-[#1D1E1B]">{task.title}</div>
        <div className="text-sm text-[#1D1E1B] mt-2">{task.description}</div>
        {task.cta && (
          <SuggestedMsg
            application_user_id={application_user_id}
            suggested_tasks_id={task.suggested_tasks_id}
            cta={task.cta}
          />
        )}
      </div>
      {!is_admin && (
        <span className="ml-auto -mt-2 -mr-2">
          <IconButton onClick={onDelete} disabled={isDeleting}>
            {!isDeleting ? (
              <Trash className="stroke-[#6A6A6A]" />
            ) : (
              <Loader size={20} />
            )}
          </IconButton>
        </span>
      )}
    </div>
  );
};

const CheckButton = ({
  isLoading,
  onCheck,
  checked,
}: {
  isLoading?: boolean;
  onCheck: () => void;
  checked?: boolean;
}) => {
  if (checked) {
    return (
      <div className="h-6 w-6 rounded-full border border-solid border-primary-color bg-primary-color flex justify-center items-center">
        <WhiteCheck stroke="white" className="w-4" />
      </div>
    );
  }
  return !isLoading ? (
    <div
      onClick={onCheck}
      className="h-6 w-6 rounded-full border border-solid border-[#8D8E92] hover:border-darkGray justify-center cursor-pointer hover:shadow-lg transition ease-in-out duration-200"
    />
  ) : (
    <Loader size={24} />
  );
};
