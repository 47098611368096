import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import { UploadContactRequestType } from "helpers/types/contacts";

const contacts = {
  uploadContacts(payload: UploadContactRequestType) {
    return apiClient
      .post(API.MANAGE_CONTACTS, {
        action: "upload_contact",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default contacts;
