import React, { useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { twMerge } from "tailwind-merge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogTitleProps } from "@mui/material/DialogTitle";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  show: boolean;
  toggle?: () => void;
  title?: string | React.ReactNode;
  subTitle?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  papperProps?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  scrolllock?: boolean | 0 | 1;
}

const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

const StyledDialog = styled(Dialog)<{ scrolllock?: boolean | 0 | 1 }>`
  .MuiDialogContent-root {
    padding-right: 8px;
    z-index: 1;
    &::-webkit-scrollbar {
      width: 4px;
      margin: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.1rem;
      height: 2px;
      background-color: #dddddd;
    }

    ${({ scrolllock }) =>
      scrolllock &&
      css`
        overflow: hidden;
      `}
  }
`;

function UIModal({
  title,
  subTitle,
  show,
  toggle,
  footer,
  children,
  papperProps,
  maxWidth = "sm",
  scrolllock = 0,
}: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useLayoutEffect(() => {
    if (_iOSDevice) {
      if (show) {
        document.body.classList.add("freezIphone");
      } else {
        document.body.classList.remove("freezIphone");
      }
    }
  }, [show]);

  return (
    <StyledDialog
      scrolllock={scrolllock || 0}
      open={show}
      onClose={toggle}
      PaperProps={{
        ...papperProps,
        className: twMerge(
          "ui-modal-wrapper md:p-8 p-4 rounded-2xl w-full h-fit max-h-[90vh] overflow-y-auto",
          papperProps?.className
        ),
      }}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      sx={{
        backdropFilter: "blur(5px)",
        zIndex: 999,
        padding: "1rem",
      }}
    >
      <BootstrapDialogTitle onClose={toggle}>
        <div className="text-xl font-semibold">{title}</div>
        {subTitle && (
          <div className="text-darkGray font-normal mt-2">{subTitle}</div>
        )}
      </BootstrapDialogTitle>

      <DialogContent className="p-0">{children}</DialogContent>

      {!!footer && <DialogActions>{footer}</DialogActions>}
    </StyledDialog>
  );
}

export default UIModal;

const BootstrapDialogTitle = (
  props: DialogTitleProps & {
    onClose?: () => void;
  }
) => {
  const { children, onClose } = props;

  return (
    <h2 className="p-0 text-lg mt-0 font-bold mb-3 mr-3">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: { md: 16, xs: 0 },
            top: { md: 16, xs: 0 },
            color: "#6A6A6A",
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </h2>
  );
};
