import UploadToAWS from "./UploadToAWS";
import { ContactType } from "helpers/types/contacts";
import { ReactComponent as Upload } from "assets/icons/upload.svg";
import { ReactComponent as Cross } from "assets/icons/cross.svg";

type Props = {
  contactsFile?: File | null;
  onContactsChange: (data: {
    contacts: ContactType[];
    file: File | null;
  }) => void;
};

const UploadCsv = ({ contactsFile, onContactsChange }: Props) => {
  const handleFileChange = (file: File) => {
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event: ProgressEvent<FileReader>) {
        const csvOutput = event?.target?.result;
        if (csvOutput) csvFileToArray(csvOutput + "", file);
      };
      fileReader.readAsText(file);
    }
  };

  /** @function This will convert the csv file into an array of strings
   * @param csv - The csv file content
   */
  const csvFileToArray = (csvText: string, file: File) => {
    const csvRows = csvText?.slice(csvText?.indexOf("\n") + 1).split("\n");

    const headings = csvRows[2]?.split(",");
    const emailIndex = headings?.indexOf("Email Address");
    const urlIndex = headings?.indexOf("URL");
    const companyIndex = headings?.indexOf("Company");
    const roleIndex = headings?.indexOf("Position");

    const contactsArray: ContactType[] = csvRows
      ?.slice(3, csvRows.length - 1)
      .map((i: string) => {
        const values = i.split(",");
        const name = values[0] + " " + values[1];
        const contact = {
          name: name?.trim(),
          url: urlIndex > -1 ? values[urlIndex]?.trim() : "",
          email: values[emailIndex || 2]?.trim(),
          company: values[companyIndex || 3]?.trim(),
          role: values[roleIndex || 4]?.trim(),
        };
        return contact;
      });
    const filteredContacts = contactsArray?.filter(
      (i: ContactType) =>
        i.name !== " " &&
        i.name !== "" &&
        i.name !== undefined &&
        i.name !== "undefined"
    );
    onContactsChange({
      contacts: filteredContacts,
      file: file,
    });
    console.log("Contacts: ", filteredContacts);
  };

  const removeFile = () => {
    onContactsChange({
      contacts: [],
      file: null,
    });
  };

  return (
    <>
      <UploadToAWS allowedFileTypes={["csv"]} onFileSelect={handleFileChange}>
        <div className="flex flex-col w-full justify-center items-center bg-[#F5F5FF] rounded-lg border-dashed border-[1.5px] border-primary-color cursor-pointer h-36">
          <Upload className="stroke-primary-color" />
          <span className="mt-3">Click to upload (.csv)</span>
        </div>
      </UploadToAWS>

      {contactsFile && (
        <div className="flex items-center gap-2 mt-4">
          <div className="text-gray-800">{contactsFile?.name}</div>
          <Cross className="cursor-pointer" onClick={removeFile} />
        </div>
      )}
    </>
  );
};

export default UploadCsv;
