import { useNavigate } from "react-router-dom";
import { ClickAwayListener, Fade } from "@mui/material";
import { redirectionUrls } from "consts/SWITCH_URLS";
import { ROUTES } from "app-router/routes";

type Props = {
  toggle: () => void;
  onLogout: () => void;
  is_admin?: boolean;
};

const ProfileMenu = ({ toggle, onLogout, is_admin }: Props) => {
  const navigate = useNavigate();
  const goToProfile = () => {
    navigate(ROUTES.PROFILE);
  };

  const goToAdminPortal = () => {
    const host = window.location.hostname;
    let redirectUrl = redirectionUrls[host];
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={toggle}>
        <Fade
          in
          className="options p-2 rounded-lg absolute top-14 right-0 z-[9] bg-[#fff] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] py-3 transition ease-in-out duration-300"
        >
          <div>
            <div
              className="font-medium px-4 py-2 hover:bg-blue-tint rounded-md"
              onClick={goToProfile}
            >
              Profile
            </div>
            {is_admin && (
              <div
                className="font-medium px-4 py-2 hover:bg-blue-tint rounded-md w-[200px]"
                onClick={goToAdminPortal}
              >
                Back to Admin Portal
              </div>
            )}
            <div
              className="font-medium text-[#DE1717] px-4 py-2 hover:bg-blue-tint rounded-md"
              onClick={onLogout}
            >
              Logout
            </div>
          </div>
        </Fade>
      </ClickAwayListener>
    </>
  );
};

export default ProfileMenu;
