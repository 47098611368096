import { useEffect } from "react";
import classNames from "classnames";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, IconButton } from "@mui/material";
import { LoadingButton } from "components/buttons";
import UIModal from "components/UIModal";
import TextInput from "components/TextInput";
import CharactersCount from "components/CharactersCount";
import useToggle from "helpers/hooks/useToggle";
import notes from "helpers/http/notes";
import { CommonResponseType } from "helpers/types/response.types";
import { EditNoteRequestType } from "helpers/types/notes.types";
import { showError, showMsg } from "helpers/misc";
import { NoteType } from "helpers/types/applications.types";
import {
  NOTE_DESCRIPTION_ERROR,
  NOTE_MAX_CHAR_ERROR,
  NOTE_TITLE_ERROR,
} from "consts/ERRORS";
import INPUT_MAXLENGTH from "consts/INPUT_MAXLENGTH";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

type Props = {
  className?: string;
  note_data: NoteType;
};

const DEFAULT_VALUES = {
  title: "",
  note: "",
};

const schema = yup
  .object({
    title: yup.string().required(NOTE_TITLE_ERROR),
    note: yup
      .string()
      .required(NOTE_DESCRIPTION_ERROR)
      .max(INPUT_MAXLENGTH.NOTE, NOTE_MAX_CHAR_ERROR),
  })
  .required();

const EditNote = ({ className, note_data }: Props) => {
  const [isOpen, toggle] = useToggle();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate } = useMutation<
    CommonResponseType,
    string,
    EditNoteRequestType
  >((data) => notes.editNote(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        showMsg(res?.message);
        toggle();
        queryClient.invalidateQueries(["get-contact-notes"]);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const onSubmit = (data: typeof DEFAULT_VALUES) => {
    mutate({
      ...data,
      note_id: note_data?.note_id,
    });
  };

  useEffect(() => {
    if (isOpen) {
      setValue("title", note_data?.title, {
        shouldValidate: true,
      });
      setValue("note", note_data?.note, {
        shouldValidate: true,
      });
    }
  }, [isOpen, note_data?.note, note_data?.title, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <IconButton onClick={toggle} className="p-1">
        <EditIcon />
      </IconButton>

      <UIModal
        show={isOpen}
        toggle={toggle}
        title="Edit Note"
        maxWidth="md"
        footer={
          <LoadingButton
            type="submit"
            loading={isLoading}
            className="mt-4 ml-auto"
          >
            Save
          </LoadingButton>
        }
        papperProps={{
          component: "form",
          onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmit(onSubmit);
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextInput
              {...register("title")}
              label="Note title"
              placeholder="Enter title"
              helperText={errors?.title?.message?.toString()}
              error={!!errors?.title}
            />
          </Grid>
          <Grid item xs={12} md={12} className="relative">
            <TextInput
              {...register("note")}
              label="Description"
              placeholder="Enter description here"
              helperText={errors?.note?.message?.toString()}
              error={!!errors?.note}
              multiline
              maxLength={null}
              rows={4}
            />
            <CharactersCount
              className={classNames("", {
                "bottom-0": errors?.note,
                "-bottom-6": !errors?.note,
              })}
              maxCharacters={INPUT_MAXLENGTH.NOTE}
              charactersCount={watch("note")?.length}
            />
          </Grid>
          <div className="h-[30px] w-[30px]" />
        </Grid>
      </UIModal>
    </form>
  );
};

export default EditNote;
