import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useOutletContext } from "react-router-dom";
import { Grid } from "@mui/material";
import NoContactsState from "components/network/NoContactsState";
import NoDataFound from "components/NoDataFound";
import LoadMoreButton from "components/LoadMoreButton";
import ReachoutMessageModal from "components/network/ReachoutMessageModal";
import ContactCard from "./ContactCard";
import network from "helpers/http/network";
import { FilterTypes, NetworkContactType } from "helpers/types/network.types";

const LIMIT = 10;

const YourContacts = () => {
  const {
    filters,
    setLoading,
  }: { filters: FilterTypes; setLoading: (val: Boolean) => void } =
    useOutletContext();

  const [reachoutModal, setReachoutModal] = useState<{
    open: boolean;
    item: NetworkContactType | null;
  }>({
    open: false,
    item: null,
  });

  const {
    data,
    isFetching,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery(
    ["get-your-contacts", filters],
    ({ pageParam = 1 }) => {
      return network.getContacts({
        limit: LIMIT,
        page: pageParam,
        keyword: filters?.search,
        location: filters?.location,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentDataCount = allPages.reduce(
          (sum, item) => (sum += item?.data?.contactData?.length),
          0
        );
        const total = allPages[0]?.data?.total;
        const hasNext =
          Number(currentDataCount) < Number(total)
            ? Math.ceil(Number(currentDataCount) / LIMIT) + 1
            : false;
        return hasNext;
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x?.data?.contactData),
        pageParams: data.pageParams,
      }),
    }
  );

  const contactsData: NetworkContactType[] = useMemo(
    () => data?.pages || [],
    [data?.pages]
  );

  const handleReachout = (item: NetworkContactType) => () => {
    setReachoutModal({ open: true, item });
    // if (item.url) {
    //   window.open(item.url, "blank");
    // } else {
    // }
  };

  const toggleReachoutModal = () => {
    setReachoutModal({ open: false, item: null });
  };

  useEffect(() => {
    if (!isFetchingNextPage) {
      setLoading(isRefetching || isLoading);
    }
  }, [isRefetching, setLoading, isFetchingNextPage, isLoading]);

  return (
    <>
      <Grid container className="mt-2" spacing={3}>
        {contactsData?.length > 0 &&
          contactsData?.map((item: NetworkContactType) => (
            <Grid item md={6} xs={12} key={item.contact_user_id}>
              <ContactCard
                item={item}
                type="your_contact"
                handleReachout={handleReachout(item)}
              />
            </Grid>
          ))}

        {contactsData?.length > 0 && (
          <LoadMoreButton
            hasNextPage={hasNextPage}
            isLoading={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            className="flex items-center justify-center mt-5 w-full"
          />
        )}
      </Grid>

      {!isFetching &&
        contactsData?.length === 0 &&
        !filters.location?.country_code &&
        !filters.search && <NoContactsState />}

      {!isFetching &&
        contactsData?.length === 0 &&
        (!!filters.location?.country_code || !!filters.search) && (
          <NoDataFound
            containerClassName="flex h-[72vh] justify-center"
            noDataText="No contacts found"
          />
        )}
      <ReachoutMessageModal
        isOpen={reachoutModal.open}
        contact_user_id={reachoutModal.item?.contact_user_id || ""}
        toggle={toggleReachoutModal}
      />
    </>
  );
};

export default YourContacts;
