import { ROUTES } from "app-router/routes";

export const NAVS = [
  {
    name: "Home",
    path: ROUTES.HOME,
    sub_path: ROUTES.HOME,
  },
  {
    name: "Applications",
    path: ROUTES.APPLICATIONS,
    sub_path: "/application-details",
  },
  {
    name: "Network",
    path: ROUTES.NETWORK,
    sub_path: ROUTES.NETWORK_DETAILS,
  },
];
