import React from "react";
import { StyledEngineProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "context/AuthContext";
import AppRouter from "./app-router/Router";
import CssBaseline from "@mui/material/CssBaseline";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      refetchOnMount: true,
      suspense: false,
    },
    mutations: {
      retry: false,
    },
  },
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <div className="App">
            <CssBaseline />
            <AppRouter />
          </div>
        </AuthProvider>
      </QueryClientProvider>
      <Toaster />
    </StyledEngineProvider>
  );
}

export default App;
