import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const SwitchingToAdminMode = lazy(() => import("modules/SwitchToAdminMode"));
export default function Page404() {
  return (
    <SuspenseWrapper>
      <SwitchingToAdminMode />
    </SuspenseWrapper>
  );
}
