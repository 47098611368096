import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const NetworkDetails = lazy(() => import("modules/network-details"));
export default function NetworkDetailsPage() {
  return (
    <SuspenseWrapper>
      <NetworkDetails />
    </SuspenseWrapper>
  );
}
