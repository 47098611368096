import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useOutletContext } from "react-router-dom";
import { Grid } from "@mui/material";
import * as yup from "yup";
import moment from "moment";
import classNames from "classnames";
import { useEffect } from "react";
import { Button, LoadingButton } from "components/buttons";
import UIModal from "components/UIModal";
import TextInput from "components/TextInput";
import ErrorMessage from "components/ErrorMessage";
import SelectInput from "components/SelectInput";
import useToggle from "helpers/hooks/useToggle";
import INPUT_MAXLENGTH from "consts/INPUT_MAXLENGTH";
import { CommonResponseType } from "helpers/types/response.types";
import { showError, showMsg } from "helpers/misc";
import { ACTIVITY_TYPES } from "consts/ACTIVITY_TYPES";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import network from "helpers/http/network";
import {
  AddActivityRequestType,
  UpdateNetworkStatusResponseType,
} from "helpers/types/network.types";
import CharactersCount from "components/CharactersCount";
import { NOTE_MAX_CHAR_ERROR } from "consts/ERRORS";
import DateInput from "components/DateInput";

type Props = {
  className?: string;
};

const today = new Date();

export const schema = yup
  .object({
    activity_type: yup.string().required("Please select activity type"),
    contact_details: yup
      .string()
      .matches(
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter valid contact details"
      )
      .required("Please enter contact details"),
    date: yup.string().required("Please select date"),
    time: yup.string().required("Please select time"),
    note: yup
      .string()
      .required("Please enter note")
      .max(INPUT_MAXLENGTH.NOTE, NOTE_MAX_CHAR_ERROR),
  })
  .required();

const DEFAULT_VALUES = {
  activity_type: "",
  contact_details: "",
  date: "",
  time: "",
  note: "",
};

const AddActivity = ({ className }: Props) => {
  const {
    contactId,
    isRecommended,
  }: { contactId: string; isRecommended: boolean } = useOutletContext();
  const [isOpen, toggle] = useToggle();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  const { isLoading, mutate: addActivity } = useMutation<
    CommonResponseType,
    string,
    AddActivityRequestType
  >((data) => network.addNetworkActivity(data), {
    onSuccess: async (res, variables) => {
      if (res?.status) {
        showMsg(res?.message);
        toggle();
        if (isRecommended) {
          window.location.replace(
            `/network-details/${variables.contact_user_id}`
          );
        } else {
          queryClient.invalidateQueries(["get-network-activities"]);
        }
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const { isLoading: isCloning, mutate: changeStatus } = useMutation<
    UpdateNetworkStatusResponseType,
    string,
    string
  >((data) => network.cloneContact(data), {
    onSuccess: async (res: UpdateNetworkStatusResponseType) => {
      if (res?.status) {
        if (res?.data?.contact_user_id) {
          handleAddActivity(res?.data?.contact_user_id);
        }
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const handleAddActivity = (contact_user_id: string) => {
    const data = getValues();
    const date_time = data.date + " " + data.time;
    const utcDate = moment(date_time.replace(/-/g, "/"))
      .utc()
      .format("YYYY-MM-DD hh:mm:ss A");
    console.log("date_time: ", date_time);
    console.log("utcDate: ", utcDate);
    const payload = {
      contact_user_id,
      contact_details: data?.contact_details,
      activity_type: data?.activity_type,
      note: data?.note,
      date_time: utcDate,
    };
    addActivity(payload);
  };

  const onSubmit = (data: typeof DEFAULT_VALUES) => {
    if (isRecommended) {
      changeStatus(contactId);
    } else {
      handleAddActivity(contactId);
    }
  };

  const onTypeChange = (e: any) => {
    setValue("activity_type", e.target.value, { shouldValidate: true });
  };

  useEffect(() => {
    if (isOpen) {
      reset(DEFAULT_VALUES);
    }
  }, [isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Button variant="text" className="group lg:p-0 p-1" onClick={toggle}>
        <PlusIcon className="stroke-primary-color group-hover:stroke-lightblack" />{" "}
        <span className="lg:text-base text-sm font-semibold">Add</span>
      </Button>

      <UIModal
        show={isOpen}
        toggle={toggle}
        title="Add activity"
        maxWidth="md"
        footer={
          <div className="flex justify-end mt-4">
            <LoadingButton type="submit" loading={isLoading || isCloning}>
              Save
            </LoadingButton>
          </div>
        }
        papperProps={{
          component: "form",
          onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmit(onSubmit);
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectInput
              placeholder="Select"
              label="Activity type"
              fullWidth
              value={getValues("activity_type")}
              options={ACTIVITY_TYPES}
              onChange={onTypeChange}
            />
            <ErrorMessage error={errors?.activity_type?.message?.toString()} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              {...register("contact_details")}
              label="Contact details (Email/phone)"
              placeholder="Enter contact detail"
              helperText={errors?.contact_details?.message?.toString()}
              error={!!errors?.contact_details}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              {...register("date")}
              label="Date"
              type="date"
              helperText={errors?.date?.message}
              error={!!errors?.date}
              InputProps={{
                inputProps: { min: moment(today).format("YYYY-MM-DD") },
              }}
            />
            {/* <DateInput label="Date" /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              {...register("time")}
              label="Time"
              type="time"
              placeholder="--:-- --"
              helperText={errors?.time?.message}
              error={!!errors?.time}
            />
          </Grid>
          <Grid item xs={12} md={12} className="relative">
            <TextInput
              {...register("note")}
              label="Note"
              placeholder="Enter note"
              helperText={errors?.note?.message}
              error={!!errors?.note}
              maxLength={INPUT_MAXLENGTH.DESCRIPTION}
              multiline
              rows={3}
            />
            <CharactersCount
              className={classNames("", {
                "bottom-0": errors?.note,
                "-bottom-6": !errors?.note,
              })}
              maxCharacters={INPUT_MAXLENGTH.NOTE}
              charactersCount={watch("note")?.length}
            />
          </Grid>
        </Grid>
        <div className="h-[30px] w-[30px]" />
      </UIModal>
    </form>
  );
};

export default AddActivity;
