import { useState } from "react";
import { Grid } from "@mui/material";
import ContactCard from "modules/network/ContactCard";
import NoDataFound from "components/NoDataFound";
import ReachoutMessageModal from "components/network/ReachoutMessageModal";
import LoadMoreButton from "components/LoadMoreButton";
import { useAuth } from "context/AuthContext";
import { NetworkContactType } from "helpers/types/network.types";

const Networks = () => {
  const { globalSearchResultsData, globalSearch } = useAuth();

  const [reachoutModal, setReachoutModal] = useState<{
    open: boolean;
    item: NetworkContactType | null;
  }>({
    open: false,
    item: null,
  });

  const handleReachout = (item: NetworkContactType) => () => {
    setReachoutModal({ open: true, item });
  };

  const toggleReachoutModal = () => {
    setReachoutModal({ open: false, item: null });
  };

  return (
    <>
      <Grid container spacing={2} className="mt-3">
        {globalSearchResultsData?.data?.map((item: any) => (
          <Grid item xs={12} md={6} sm={6} lg={4} key={item.contact_id}>
            <ContactCard
              item={item}
              type="your_contact"
              handleReachout={handleReachout(item)}
            />
          </Grid>
        ))}

        {!!globalSearchResultsData?.data?.length && (
          <LoadMoreButton
            hasNextPage={globalSearchResultsData?.hasMore}
            isLoading={Boolean(globalSearchResultsData?.isFetchingNext)}
            fetchNextPage={globalSearchResultsData?.fetchNext}
            className="flex items-center justify-center mt-5 w-full"
          />
        )}
      </Grid>

      {!globalSearchResultsData?.isLoading &&
        !globalSearchResultsData?.data?.length && (
          <NoDataFound
            noDataText={`No contacts found for '${globalSearch}'`}
            containerClassName="flex flex-col items-center justify-center w-full h-[72vh]"
          />
        )}

      <ReachoutMessageModal
        isOpen={reachoutModal.open}
        contact_user_id={reachoutModal.item?.contact_user_id || ""}
        toggle={toggleReachoutModal}
      />
    </>
  );
};

export default Networks;
