import Notes from "./Notes";
import Activities from "./Activities";

const APPLICATION_DETAILS_ROUTES = [
  {
    id: "activities",
    label: "Activities",
    component: Activities,
  },
  {
    id: "notes",
    label: "Notes",
    component: Notes,
  },
];

export default APPLICATION_DETAILS_ROUTES;
