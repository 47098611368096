import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Profile = lazy(() => import("modules/profile"));
export default function ProfilePage() {
  return (
    <SuspenseWrapper>
      <Profile />
    </SuspenseWrapper>
  );
}
