import INPUT_MAXLENGTH from "./INPUT_MAXLENGTH";

// Required Errors
export const FIRSTNAME_ERROR = "First name is required";
export const LASTNAME_ERROR = "Last name is required";
export const EMAIL_REQUIRED_ERROR = "Email is required";
export const PASSWORD_REQUIRED_ERROR = "Password is required";
export const PHONE_REQUIRED_ERROR = "Phone number is required";

// Errors
export const PASSWORD_ERROR =
  "Password should be 8 characters long and must include 1 capital letter, 1 number, and 1 symbol.";
export const PASSWORD_MIN_LENGTH_ERROR =
  "Password must be at least 8 characters";
export const PASSWORD_MAX_LENGTH_ERROR = `Password must be ${INPUT_MAXLENGTH.PASSWORD} characters or less`;
export const PASSWORD_MISMATCH_ERROR = "Passwords must match";
export const EMAIL_ERROR = "Please enter a valid email";
export const PHONE_ERROR = "Please enter a valid phone number";
export const URL_ERROR =
  "Please enter a valid url e.g https://www.websitename.com";

// Add Application form errors
export const APPLICATION_TITLE_ERROR = "Job title is required";
export const APPLICATION_COMPANY_NAME_ERROR = "Company name is required";
export const APPLICATION_CONTACT_ERROR = "Company contact is required";
export const APPLICATION_LOCATION_ERROR = "Company location is required";
export const APPLICATION_STATUS_ERROR = "Please select application status";
export const APPLICATION_DATE_ERROR = "Please select application date";
export const APPLICATION_JOB_URL_ERROR = "Job URL is required";
export const APPLICATION_COMPANY_URL_ERROR = "Company website URL is required";
export const APPLICATION_DESCRIPTION_ERROR = "Job description is required";

// Add note form errors
export const NOTE_TITLE_ERROR = "Note title is required";
export const NOTE_DESCRIPTION_ERROR = "Note description is required";
export const NOTE_MAX_CHAR_ERROR = `Note must be ${INPUT_MAXLENGTH.NOTE} characters or less`;

// Add contact form errors
export const CONTACT_NAME_ERROR = "Name is required";
export const CONTACT_ROLE_ERROR = "Role is required";
export const CONTACT_COMPANY_ERROR = "Company name is required";
export const CONTACT_NOTE_ERROR = "Note is required";
