import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IconButton } from "@mui/material";
import { LoadingButton } from "components/buttons";
import UIModal from "components/UIModal";
import useToggle from "helpers/hooks/useToggle";
import notes from "helpers/http/notes";
import { showError, showMsg } from "helpers/misc";
import { CommonResponseType } from "helpers/types/response.types";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

const DeleteNotePrompt = ({ note_id }: { note_id: number }) => {
  const [isOpen, toggle] = useToggle();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<CommonResponseType, string, number>(
    (data) => notes.deleteContactNote(data),
    {
      onSuccess: async (res) => {
        if (res?.status) {
          showMsg(res?.message);
          toggle();
          queryClient.invalidateQueries(["get-contact-notes"]);
          queryClient.invalidateQueries(["get-application-notes"]);
        } else {
          showError(res?.message);
        }
      },
      onError: (err) => {
        showError(err);
      },
    }
  );

  const onDelete = () => {
    mutate(note_id);
  };

  return (
    <>
      <IconButton onClick={toggle} className="p-1">
        <TrashIcon className="stroke-red" />
      </IconButton>
      <UIModal show={isOpen} toggle={toggle} title="" maxWidth="xs">
        <div className="text-center text-2xl font-medium">
          Are you sure you want to delete this note?
        </div>
        <div className="flex justify-center mt-6">
          <LoadingButton
            className="w-[214px] max-w-full"
            loading={isLoading}
            disabled={isLoading}
            onClick={onDelete}
          >
            Okay
          </LoadingButton>
        </div>
      </UIModal>
    </>
  );
};

export default DeleteNotePrompt;
