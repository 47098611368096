import { useEffect, useCallback } from "react";
import styled from "styled-components";
import { myTheme } from "styles/theme";
import { urlify } from "helpers/misc";

const Wrapper = styled.pre`
  font-family: "Inter", sans-serif;
  word-break: break-word;
  overflow: hidden;
  white-space: pre-wrap;
  p {
    margin: 0px;
  }
  a {
    color: ${() => myTheme.colors.primary};
  }
`;
type Props = {
  htmlString: string;
  id?: string;
  className?: string;
};

const StyledHtml = ({ htmlString, id, className }: Props) => {
  const formatDescription = useCallback(
    async (htmlString: string) => {
      let element;
      if (id) {
        element = document.getElementById(id);
      } else {
        element = document.getElementById("htmlString");
      }
      if (element) {
        element.innerHTML = urlify(htmlString);
      }
    },
    [id]
  );

  useEffect(() => {
    if (htmlString) {
      const htmlStringFormatted = htmlString?.replace(/(<p>&nbsp;<\/p>)+$/, "");
      formatDescription(htmlStringFormatted);
    }
  }, [htmlString, id, formatDescription]);

  return (
    <Wrapper className={className}>
      <span id={id || "htmlString"}></span>
    </Wrapper>
  );
};

export default StyledHtml;
