import { NoteType } from "helpers/types/applications.types";
import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import WrappedContent from "components/WrappedContent";
import Loader from "components/loaders/Loader";
import NoDataFound from "components/NoDataFound";
import DeleteNotePrompt from "components/DeleteNotePrompt";
import AddEditNote from "./components/AddEditNote";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";
import notes from "helpers/http/notes";
import { getLocalDate } from "helpers/misc";
import { ReactComponent as NoteIcon } from "assets/images/note.svg";
import { useAuth } from "context/AuthContext";

const Notes = () => {
  const { application_details } = useApplicationDetails();
  const { user } = useAuth();
  const { data, isLoading } = useQuery(
    ["get-application-notes", application_details.application_user_id],
    () => notes.getNotes(application_details.application_user_id)
  );
  return (
    <>
      <Grid container spacing={3} className="mb-6">
        {isLoading && (
          <Loader className="flex items-center justify-center flex-1 mt-3" />
        )}
        {!isLoading &&
          data?.data?.noteResult?.map((item: NoteType) => (
            <NoteCard
              key={item.note_id}
              item={item}
              is_admin={user?.is_admin}
            />
          ))}
      </Grid>
      {!isLoading && data?.data?.noteResult?.length === 0 && (
        <NoDataFound
          noDataText="No notes found"
          containerClassName="flex items-center justify-center w-full my-12 mt-16 h-auto"
        />
      )}
    </>
  );
};

export default Notes;

const NoteCard = ({
  item,
  is_admin,
}: {
  item: NoteType;
  is_admin?: boolean;
}) => {
  return (
    <Grid item xs={12} md={6}>
      <WrappedContent className="flex gap-4 p-4 border border-solid border-lightgray rounded-xl h-full">
        <span>
          <NoteIcon />
        </span>
        <div className="flex-1">
          <div className="flex gap-2 justify-between">
            <div className="font-semibold capitalize">{item?.title}</div>
            {!is_admin && (
              <div className="flex items-start gap-1">
                <AddEditNote note_data={item} />
                <DeleteNotePrompt note_id={item.note_id} />
              </div>
            )}
          </div>
          <div className="text-sm text-darkGray mt-1">{item?.note}</div>
          <div className="text-xs dim-text mt-2">
            {getLocalDate(item?.date_created)}
          </div>
        </div>
      </WrappedContent>
    </Grid>
  );
};
