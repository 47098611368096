import { useQuery } from "@tanstack/react-query";
import { useOutletContext } from "react-router-dom";
import { Grid } from "@mui/material";
import NoDataFound from "components/NoDataFound";
import Loader from "components/loaders/Loader";
import network from "helpers/http/network";
import AddActivity from "./AddActivity";
import NetworkActivityCard from "./NetworkActivityCard";
import { NetworkActivityTypes } from "helpers/types/network.types";
import { useAuth } from "context/AuthContext";

const YourActivites = () => {
  const {
    contactId,
    isRecommended,
  }: { contactId: string; isRecommended: boolean } = useOutletContext();
  const { user } = useAuth();

  const { data, fetchStatus } = useQuery(
    ["get-network-activities", contactId],
    () => network.getNetworkActivities(contactId),
    {
      enabled: !!contactId && !isRecommended,
      keepPreviousData: true,
    }
  );

  return (
    <div className="p-6 border border-solid border-lightgray rounded-xl flex-1">
      <header className="flex items-center md:gap-2 gap-1 mb-5">
        <span className="text-lg font-semibold">Activities added by you</span>
        {!user?.is_admin && (
          <div className="ml-auto">
            <AddActivity />
          </div>
        )}
      </header>

      {fetchStatus === "fetching" && (
        <Loader className="flex items-center justify-center" />
      )}

      <Grid
        container
        spacing={2}
        className="max-h-[50vh] overflow-y-auto overflow-x-hidden custom-scroll pt-1"
      >
        {fetchStatus === "idle" &&
          data?.data?.activityData?.map((item: NetworkActivityTypes) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={item.activity_id}>
              <NetworkActivityCard item={item} />
            </Grid>
          ))}
      </Grid>
      {fetchStatus === "idle" && !data?.data?.activityData?.length && (
        <NoDataFound
          noDataText="No activities found"
          containerClassName="my-7 flex items-center justify-center w-full h-full"
        />
      )}
    </div>
  );
};

export default YourActivites;
