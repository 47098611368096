import YourActivities from "./components/YourActivities";
import SuggestedActivities from "./components/SuggestedActivities";

const Activities = () => {
  return (
    <div className="flex flex-col gap-6">
      <SuggestedActivities />
      <YourActivities />
    </div>
  );
};

export default Activities;
