import RecommendedContacts from "./RecommendedContacts";
import YourContacts from "./YourContacts";

const NETWORK_ROUTES = [
  {
    id: "recommended-contacts",
    label: "Recommended contacts",
    component: RecommendedContacts,
  },
  {
    id: "your-contacts",
    label: "Your contacts",
    component: YourContacts,
  },
];

export default NETWORK_ROUTES;
