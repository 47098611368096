import React from "react";
import styled from "styled-components";
import { MenuItem, Select, SelectProps } from "@mui/material";
import down from "assets/icons/down.svg";

type Props = SelectProps & {
  containerClassName?: string;
  label?: string;
  value: string;
  options: {
    label: string;
    value: string;
  }[];
};

const InputWrapper = styled.div`
  label {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const StyledSelect = styled.select`
  padding: 0 1rem;
  width: 100%;
  height: 50px;
  background: #ffffff url(${down}) no-repeat right 10px center;
  border-radius: 7px;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  outline: none;
  box-shadow: none;
  border: 1px solid #d9d9e7;
  cursor: pointer;
`;

const SelectInput = (
  {
    label,
    containerClassName,
    type = "text",
    value,
    name,
    options,
    ...props
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <InputWrapper className={containerClassName}>
      <label htmlFor={label}>{label}</label>
      <div className="relative mt-[0.2rem]">
        {/** Placeholder */}

        {props.placeholder && !value && (
          <div className="absolute z-[1] top-[30%] left-4 text-[#99989c] font-medium">
            {props.placeholder}
          </div>
        )}

        <Select
          id="demo-simple-select"
          labelId="label"
          value={value}
          ref={ref}
          className="select-container"
          {...props}
        >
          {options.map((opt) => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </InputWrapper>
  );
};

export const NativeSelect = ({
  label,
  containerClassName,
  type = "text",
  value,
  name,
  options,
  onChange,
  ...props
}: any) => {
  return (
    <InputWrapper className={containerClassName}>
      <label htmlFor={label}>{label}</label>
      <div className="relative">
        <StyledSelect value={value} onChange={onChange} name={name} {...props}>
          <option value="" disabled selected>
            Select
          </option>
          {options.map((opt: { label: string; value: string }) => (
            <option value={opt.value} key={opt.value}>
              {opt.label}
            </option>
          ))}
        </StyledSelect>
      </div>
    </InputWrapper>
  );
};

export default React.forwardRef(SelectInput);
