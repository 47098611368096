import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import { EditSuggestedTaskTypes } from "helpers/types/suggested.types";

const suggestedTasks = {
  getSuggestedTasks(application_user_id: string) {
    return apiClient
      .post(API.MANAGE_SUGGESTED_TASKS, {
        action: "get_task",
        application_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  editSuggestedTask(payload: EditSuggestedTaskTypes) {
    return apiClient
      .post(API.MANAGE_SUGGESTED_TASKS, {
        action: "edit_task",
        is_completed: 1,
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteSuggestedTask(payload: EditSuggestedTaskTypes) {
    return apiClient
      .post(API.MANAGE_SUGGESTED_TASKS, {
        action: "delete_task",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getSuggestedReachouts(application_user_id: string) {
    return apiClient
      .post(API.MANAGE_SUGGESTED_REACHOUTS, {
        action: "get_reach_out",
        application_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  regenerateSuggestedTaskMsg(payload: EditSuggestedTaskTypes) {
    return apiClient
      .post(API.MANAGE_SUGGESTED_TASKS, {
        action: "get_recommended_message",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default suggestedTasks;
