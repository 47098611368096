const DRAWER_WIDTH = 275;
const DEFAULT_AVATAR = "/images/default_avatar.png";
const COMPANY_LOGO = "/logo.svg";
const DEFAULT_COMPANY_ICON = "/images/default_company_icon.svg";
const DEFAULT_SMALL_COMPANY_ICON = "/images/logo-icon.svg";
const LINKEDIN_LOGO_SMALL = "/images/linkedin.svg";
const LIST_SEARCH_LIMIT = 10;

export const CONFIG = {
  DRAWER_WIDTH,
  DEFAULT_AVATAR,
  COMPANY_LOGO,
  DEFAULT_COMPANY_ICON,
  DEFAULT_SMALL_COMPANY_ICON,
  LINKEDIN_LOGO_SMALL,
  LIST_SEARCH_LIMIT,
};
