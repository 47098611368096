import { Drawer } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "components/buttons";
import { useAuth } from "context/AuthContext";
import { NAVS } from "consts/NAVS";
import { redirectionUrls } from "consts/SWITCH_URLS";
import classNames from "classnames";
import { ROUTES } from "app-router/routes";
import ProfileAvatar from "components/ProfileAvatar";

type Props = {
  toggleDrawer: () => void;
  isOpen: boolean;
  onLogout: () => void;
};

const Wrapper = styled.div`
  .active {
    font-weight: bold;
  }
`;

const Sidebar = ({ toggleDrawer, isOpen, onLogout }: Props) => {
  const { user } = useAuth();

  const handleLogout = () => {
    toggleDrawer();
    onLogout();
  };

  const goToAdminPortal = () => {
    const host = window.location.hostname;
    let redirectUrl = redirectionUrls[host];
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  };

  return (
    <>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
        <Wrapper className="flex flex-col min-w-[290px] flex-1 items-start">
          <Link to={ROUTES.PROFILE} className="flex items-center p-4 my-2">
            <img src="/logo.svg" alt="jackfruit-logo" className="h-100 w-100" />
          </Link>
          <Link
            to={ROUTES.PROFILE}
            className="flex items-center gap-3 mb-3 border-0 border-t border-b border-solid border-lightgray w-full p-4 no-underline text-inherit"
            onClick={toggleDrawer}
          >
            <ProfileAvatar
              imageurl={user?.user_image}
              name={user?.first_name + " " + user?.last_name}
              className="h-[80px] w-[80px] rounded-full object-cover"
            />
            <div>
              <div className="font-semibold text-lg capitalize">
                {user?.first_name} {user?.last_name}
              </div>
              <div className="text-sm capitalize">{user?.u_email_id}</div>
            </div>
          </Link>
          <div className="flex-1 px-4">
            {NAVS.map((nav) => (
              <div key={nav.name} className="p-2">
                <NavLink
                  className={classNames("no-underline text-black", {
                    active:
                      window.location.pathname?.includes(nav.path) ||
                      window.location.pathname?.includes(nav.sub_path),
                  })}
                  to={nav.path}
                  onClick={toggleDrawer}
                >
                  {nav.name}
                </NavLink>
              </div>
            ))}
          </div>
          <div className="flex flex-col m-4 items-start">
            {user?.is_admin && (
              <Button variant="text" onClick={goToAdminPortal} className="px-1">
                Back to Admin Portal
              </Button>
            )}
            <Button
              variant="text"
              className="text-red px-0"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </Wrapper>
      </Drawer>
    </>
  );
};

export default Sidebar;
