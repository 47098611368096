import React from "react";
import styled from "styled-components";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import { twMerge } from "tailwind-merge";
import INPUT_MAXLENGTH from "consts/INPUT_MAXLENGTH";
import { ReactComponent as EyeSlash } from "assets/icons/eye-slash.svg";
import { ReactComponent as Eye } from "assets/icons/eye.svg";

const InputWrapper = styled.div`
  label {
    font-size: 0.875rem;
  }
  input {
    font-family: "IBM Plex Sans", sans-serif;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Input = styled(TextField)`
  border-radius: 4px;
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiInputBase-root {
    border: 1px solid #d9d9e7;
    :disabled {
      border-radius: 4px;
      background-color: #f5f4f8;
    }
  }
  .MuiFormHelperText-root {
    margin: 0.25rem 0 0 0;
  }
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
`;

type Props = TextFieldProps & {
  label?: string;
  type?: string;
  maxLength?: number | null;
  className?: string;
  enableReveal?: boolean;
  labelClassName?: string;
};

const TextInput = (
  {
    label,
    type = "text",
    maxLength = INPUT_MAXLENGTH.DEFAULT,
    className,
    enableReveal,
    labelClassName,
    ...props
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const [revealPassword, setRevealPassword] = React.useState(false);
  const toggleRevealPassword = () => setRevealPassword(!revealPassword);

  const inputType = React.useMemo(() => {
    if (enableReveal) {
      return revealPassword ? "text" : "password";
    }
    return type;
  }, [enableReveal, revealPassword, type]);

  return (
    <InputWrapper className={twMerge("flex flex-col", className)}>
      {!!label && (
        <label
          htmlFor={label}
          className={twMerge("mb-1 opacity-80", labelClassName)}
        >
          <div className={labelClassName}>{label}</div>
        </label>
      )}
      <Input
        inputProps={{
          maxLength,
        }}
        InputProps={{
          endAdornment: enableReveal ? (
            <IconButton onClick={toggleRevealPassword}>
              {!revealPassword ? (
                <EyeSlash stroke="#2A2B2F" />
              ) : (
                <Eye stroke="#2A2B2F" />
              )}
            </IconButton>
          ) : null,
        }}
        type={inputType}
        inputRef={ref}
        {...props}
      />
    </InputWrapper>
  );
};

export default React.forwardRef(TextInput);
