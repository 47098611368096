// General
export const UPLOAD_IMAGE = "/general/image/upload";
export const GET_SETTINGS = "/general/setting/get";

// Auth
export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register/user";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const RESET_PASSWORD = "/auth/reset-password";

// User
export const MANAGE_USER = "/user/manage_user";

// Contacts
export const MANAGE_CONTACTS = "/contact/manage-contact";

// Todo
export const MANAGE_TODO = "/todo-list/manage-todo-list";

// Home
export const GET_LIST = "/home/get-list";
export const GET_HOME_COUNTS = "/home/get-home-count";
export const GET_HOME_PRO_TIP = "/home/get-pro-tip";

// Applications
export const MANAGE_APPLICATION = "/application/manage-application";
export const MANAGE_RECOMMENDED_APPLICATION =
  "/recommend-application/manage-recommend-application";
export const GET_APPLICATIONS_LIST = "/application/list/get";

// Notes
export const MANAGE_NOTE = "/note/manage-note";

// Suggested tasks
export const MANAGE_SUGGESTED_TASKS = "/application/manage-suggested-tasks";
export const MANAGE_SUGGESTED_REACHOUTS =
  "/application/manage-suggested-reachout";
export const MANAGE_QUESTIONS = "/application/manage-interview-questions";

// Network
export const MANAGE_RECOMMENDED_CONTACT =
  "/recommend-contact/manage-recommend-contact";
export const GET_NETWORK_CONTACTS = "/contact/list/get";
export const MANAGE_NETWORK_CONTACT = "/contact/manage-contact";
export const EDIT_SUGGESTED_NETWORK_ACTIVITY =
  "/contact/suggested_questions/edit";
export const MANAGE_CONTACT_ACTIVITY = "/contact/manage-activity";

export const GLOBAL_SEARCH = "/home/global/search";

export const MANAGE_EXPERIENCE = "/work_experience/manage_work_experience";
export const MANAGE_EDUCATION = "/education/manage_education";
