import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import PageLoader from "components/loaders/PageLoader";
import userAPI from "helpers/http/user";
import general from "helpers/http/general";
import { showError } from "helpers/misc";
import {
  getProfileToken,
  getToken,
  removeProfileToken,
  removeToken,
} from "helpers/storage";
import { UserType } from "helpers/types/user.types";
import { SettingsType } from "helpers/types/general.types";
import { CONFIG } from "consts/CONFIG";
import { ROUTES } from "app-router/routes";

interface AuthContextType {
  user: UserType | null;
  signin: (token: string) => void;
  goToCompleteProfile: (token: string) => void;
  signout: () => void;
  settingsData?: SettingsType | null;
  globalSearch: string;
  handleGlobalSearch: (value: string) => void;
  globalSearchResultsData?: {
    data?: any;
    isLoading?: boolean;
    hasMore?: boolean;
    fetchNext: () => void;
    isFetchingNext?: boolean;
    total?: number;
  };
}
const AuthContext = React.createContext<AuthContextType>({
  user: null,
  signin: () => {},
  goToCompleteProfile: () => {},
  signout: () => {},
  settingsData: null,
  globalSearch: "",
  handleGlobalSearch: () => {},
});

function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [globalSearch, setGlobalSearch] = React.useState<string>("");
  const [isBootstaping, setIsBootstraping] = React.useState(true);
  const [globalSearchRecordsCount, setGlobalSearchRecordsCount] =
    React.useState<number>(0);

  React.useEffect(() => {
    // check for token initally
    const token = getToken();
    if (!token) {
      setIsBootstraping(false);
    }
  }, []);

  const { data } = useQuery(["get-user-profile"], userAPI.getUser, {
    enabled: !!getToken() || !!getProfileToken(),
    onSettled: () => setIsBootstraping(false),
    onError: (err) => showError(err + ""),
    retry: 0,
  });

  const { data: settings } = useQuery(["get-settings"], general.getSettings, {
    enabled: !!getToken() || !!getProfileToken(),
    onError: (err) => showError(err + ""),
    retry: 0,
  });

  const handleGlobalSearch = React.useCallback(
    async (value: string) => {
      try {
        // alert(value);
        setGlobalSearch(decodeURI(value));
        if (!value) return;
        let redirectionPath = "/global-search/applications";
        if (window.location.pathname.includes("/global-search/networks")) {
          redirectionPath = "/global-search/networks";
        }
        navigate(`${redirectionPath}?search=${value}`);
      } catch (err) {
        console.log(err);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (window.location?.pathname?.includes("/global-search")) {
      const search = window.location?.search;
      if (search) {
        const keyword = search.split("=")[1];
        handleGlobalSearch(keyword);
      }
    }
  }, [handleGlobalSearch]);

  const {
    data: globalSearchResults,
    isFetching: isLoadingGlobalSearch,
    hasNextPage: hasMoreGlobalSearchResults,
    fetchNextPage: fetchNext,
    isFetchingNextPage: isFetchingNext,
  } = useInfiniteQuery(
    ["global-search-results", globalSearch, window.location.pathname],
    ({ pageParam = 1 }) => {
      return general.searchGloablly({
        keyword: globalSearch,
        type: window.location.pathname.split("/")[2],
        page: pageParam,
        limit: CONFIG.LIST_SEARCH_LIMIT,
      });
    },
    {
      enabled:
        !!globalSearch && window.location.pathname.includes("/global-search"),
      getNextPageParam: (lastPage, allPages) => {
        const currentDataCount = allPages.reduce(
          (sum, item) => (sum += item?.data?.listData?.length),
          0
        );
        const total = allPages[0]?.data?.total;
        const hasNext =
          Number(currentDataCount) < Number(total)
            ? Math.ceil(Number(currentDataCount) / CONFIG.LIST_SEARCH_LIMIT) + 1
            : false;
        return hasNext;
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x?.data?.listData),
        pageParams: data.pageParams,
        total_records: data?.pages?.[0]?.data?.total,
      }),
      onSuccess: (data: any) => {
        setGlobalSearchRecordsCount(data?.total_records);
      },
    }
  );

  const globalSearchResultsData = useMemo(() => {
    return {
      data: globalSearchResults?.pages,
      isLoading: isLoadingGlobalSearch,
      hasMore: hasMoreGlobalSearchResults,
      fetchNext,
      isFetchingNext,
      total: globalSearchRecordsCount,
    };
  }, [
    fetchNext,
    globalSearchRecordsCount,
    globalSearchResults?.pages,
    hasMoreGlobalSearchResults,
    isFetchingNext,
    isLoadingGlobalSearch,
  ]);

  const user = useMemo(() => {
    if (data)
      return {
        ...data?.data?.userData,
        is_admin: data?.data?.is_admin,
        education: data?.data?.education,
        workExperience: data?.data?.workExperience,
      };
  }, [data]);

  const settingsData = useMemo(() => {
    if (settings) return settings?.data;
  }, [settings]);

  const signin = React.useCallback(
    async (token: string) => {
      try {
        await localStorage.setItem("token", token);
        await queryClient.invalidateQueries(["get-user-profile"]);
        navigate(ROUTES.HOME);
      } catch (err) {
        console.log(err);
      }
    },
    [navigate, queryClient]
  );

  const goToCompleteProfile = React.useCallback(
    async (token: string) => {
      try {
        await localStorage.setItem("profile_token", token);
        navigate(ROUTES.COMPLETE_PROFILE);
      } catch (err) {
        console.log(err);
      }
    },
    [navigate]
  );

  const signout = React.useCallback(() => {
    removeToken();
    removeProfileToken();
    queryClient.setQueryData(["get-user-profile"], null);
    navigate(ROUTES.LOGIN);
  }, [navigate, queryClient]);

  const value = React.useMemo(
    () => ({
      user,
      signin,
      signout,
      goToCompleteProfile,
      settingsData,
      globalSearch,
      handleGlobalSearch,
      globalSearchResultsData,
    }),
    [
      user,
      signin,
      signout,
      goToCompleteProfile,
      settingsData,
      globalSearch,
      handleGlobalSearch,
      globalSearchResultsData,
    ]
  );

  if (isBootstaping) {
    return <PageLoader />;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
