import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Network = lazy(() => import("modules/network"));
export default function NetworkPage() {
  return (
    <SuspenseWrapper>
      <Network />
    </SuspenseWrapper>
  );
}
