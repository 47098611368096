import WrappedContent from "components/WrappedContent";
import { getLocalDate, isValidDate } from "helpers/misc";
import { NetworkActivityTypes } from "helpers/types/network.types";
import { ReactComponent as CallIcon } from "assets/images/activity-call.svg";
import { ReactComponent as CoffeeIcon } from "assets/images/activity-coffee.svg";
import { ReactComponent as InterviewIcon } from "assets/images/activity-interview.svg";
import { ReactComponent as OtherIon } from "assets/images/activity-other.svg";

type Props = {
  item: NetworkActivityTypes;
};

const NetworkActivityCard = ({ item }: Props) => {
  return (
    <div className="flex gap-3 border border-solid border-lightgray p-4 rounded-lg h-full">
      <span>
        {item.activity_type === "Call" && <CallIcon />}
        {item.activity_type === "Coffee chat" && <CoffeeIcon />}
        {item.activity_type === "Interview" && <InterviewIcon />}
        {item.activity_type === "Other" && <OtherIon />}
      </span>
      <WrappedContent>
        <div className="font-semibold">{item.activity_type}</div>
        {isValidDate(item.date_time) && (
          <div className="mt-2">
            {getLocalDate(
              item.date_time?.replace(/-/g, "/"),
              "MMM DD, YYYY | hh:mm A"
            )}
          </div>
        )}
        <span className="text-sm text-[#3A3A3A] mt-2">{item.note}</span>
      </WrappedContent>
    </div>
  );
};

export default NetworkActivityCard;
