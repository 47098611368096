import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  name?: string;
  className?: string;
  imageurl?: string;
};

const ProfileAvatar = ({ name, className, imageurl }: Props) => {
  const [hasValidImage, setHasValidImage] = useState(true);
  const invalidateImage = () => setHasValidImage(false);
  if (hasValidImage && imageurl) {
    return (
      <span className="flex items-center">
        <img
          src={imageurl}
          className={twMerge("object-cover", className)}
          alt="avatar"
          onError={invalidateImage}
        />
      </span>
    );
  }
  return (
    <span className="flex items-center">
      <div
        className={twMerge(
          "uppercase flex items-center justify-center text-primary-color font-semibold text-lg rounded-full bg-blue-tint",
          className
        )}
      >
        {name?.split(" ")[0]?.charAt(0)}
        {name?.split(" ")[name?.split(" ").length - 1]?.charAt(0)}
      </div>
    </span>
  );
};

export default ProfileAvatar;
