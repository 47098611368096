import classNames from "classnames";
import { Grow } from "@mui/material";

const steps = [1, 2, 3, 4, 5, 6];

type Props = {
  activeStep: number;
  color?: "primary" | "success";
};

const Stepper = ({ activeStep, color = "success" }: Props) => {
  const isCompleted = (index: number) => {
    return index <= activeStep;
  };
  return (
    <Grow
      in={true}
      className="flex items-center justify-center gap-3 w-full md:w-[80%]"
    >
      <div>
        {steps.map((step, index) => {
          return (
            <div
              className={classNames("w-[22%] h-[11px] rounded-full", {
                "opacity-20": !isCompleted(index),
                "bg-primary-color": color === "primary",
                "bg-[#22962E]": color === "success",
              })}
              key={step}
            />
          );
        })}
      </div>
    </Grow>
  );
};

export default Stepper;
