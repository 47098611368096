import { useMemo } from "react";
import { Link } from "react-router-dom";
import WrappedContent from "components/WrappedContent";
import { Button } from "components/buttons";
import { StyledTooltip } from "components/CustomTooltip";
import ProfileAvatar from "components/ProfileAvatar";
import {
  NetworkContactType,
  RecommendedContactType,
} from "helpers/types/network.types";
import { ReactComponent as SmsIcon } from "assets/icons/email.svg";
import { CONFIG } from "consts/CONFIG";
import { ROUTES } from "app-router/routes";

type Props = {
  item: RecommendedContactType | NetworkContactType;
  type?: "recommended" | "your_contact";
  handleReachout?: () => void;
};

const ContactCard = ({ item, type = "recommended", handleReachout }: Props) => {
  const onConnect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(item.url, "blank");
  };

  const onReachout = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleReachout && handleReachout();
  };

  const itemUrl = useMemo(() => {
    let url = "";
    if ("contact_user_id" in item) {
      url = `${ROUTES.NETWORK_DETAILS}/${item.contact_user_id}`;
    } else {
      url = `${ROUTES.RECOMMENDED_NETWORK_DETAILS}/${item.contact_id}`;
    }
    return url;
  }, [item]);

  return (
    <Link
      to={itemUrl}
      className="h-full flex items-center bg-white md:p-5 p-4 rounded-lg md:gap-4 gap-3 cursor-pointer transition ease-in-out duration-300 hover:shadow-[0px_17px_59px_0px_rgba(43,53,80,0.15)] no-underline"
    >
      <ProfileAvatar
        imageurl={item.profile_image}
        name={item.name}
        className="md:h-20 h-14 md:w-20 w-14 rounded-full object-cover text-xl"
      />

      <WrappedContent className="flex-1">
        <div className="flex item-center gap-2 text-lightblack font-semibold capitalize">
          {item.name}
          {type === "your_contact" && (
            <StyledTooltip
              title={
                item.source === "LINKEDIN"
                  ? "This contact was imported from LinkedIn"
                  : "This connection is added by you manually."
              }
            >
              <img
                alt="company-logo"
                src={
                  item.source === "LINKEDIN"
                    ? CONFIG.LINKEDIN_LOGO_SMALL
                    : CONFIG.DEFAULT_SMALL_COMPANY_ICON
                }
                className="h-5 w-5 rounded-full object-contain cursor-pointer"
              />
            </StyledTooltip>
          )}
        </div>
        {item.role && (
          <div className="flex text-darkGray text-sm mt-2 capitalize">
            {item.role}
          </div>
        )}
        {item.email && (
          <div className="flex text-darkGray text-sm mt-2 gap-2">
            <span className="flex items-center">
              <SmsIcon className="stroke-darkGray h-4 w-4" />
            </span>
            {item.email}
          </div>
        )}
      </WrappedContent>
      <span className="m-auto">
        {type === "recommended" && item.url && (
          <Button
            onClick={onConnect}
            color="secondary"
            className="md:px-inherit md:py-inherit px-4 py-2 md:text-inherit text-sm md:text-primary-color"
          >
            Connect
          </Button>
        )}
        {type === "your_contact" && (
          <Button
            onClick={onReachout}
            color="secondary"
            className="md:px-inherit md:py-inherit px-4 py-2 md:text-inherit text-sm md:text-primary-color"
          >
            Reach out
          </Button>
        )}
      </span>
    </Link>
  );
};

export default ContactCard;
