import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import {
  AddContactNoteRequestType,
  AddNoteRequestType,
  EditNoteRequestType,
} from "helpers/types/notes.types";

const notes = {
  getNotes(application_user_id: string) {
    return apiClient
      .post(API.MANAGE_NOTE, {
        action: "get_note",
        application_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getContactNotes(contact_user_id: string) {
    return apiClient
      .post(API.MANAGE_NOTE, {
        action: "get_note",
        contact_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  addApplicationNote(payload: AddNoteRequestType) {
    return apiClient
      .post(API.MANAGE_NOTE, {
        action: "add_note",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  addContactNote(payload: AddContactNoteRequestType) {
    return apiClient
      .post(API.MANAGE_NOTE, {
        action: "add_note",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  editNote(payload: EditNoteRequestType) {
    return apiClient
      .post(API.MANAGE_NOTE, {
        action: "edit_note",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteContactNote(note_id: number) {
    return apiClient
      .post(API.MANAGE_NOTE, {
        action: "delete_note",
        note_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default notes;
