import { twMerge } from "tailwind-merge";
import { Button } from "./buttons";
import { ReactComponent as Arrow } from "assets/icons/back-arrow.svg";
import { CircularProgress } from "@mui/material";

type Props = {
  isLoading: boolean;
  hasNextPage?: boolean;
  className?: string;
  fetchNextPage?: () => void;
};

const LoadMoreButton = ({
  isLoading,
  hasNextPage,
  className,
  fetchNextPage,
}: Props) => {
  if (!hasNextPage) return null;
  return (
    <div className={twMerge("flex justify-center mt-5", className)}>
      <Button
        className="group w-[160px] px-0 py-2 rounded-full text-primary-color font-bold bg-white"
        onClick={fetchNextPage}
        disabled={isLoading}
      >
        {isLoading ? "Loading" : "Load More"}{" "}
        {!isLoading && (
          <Arrow className="fill-primary-color group-hover:fill-white -rotate-90 h-5" />
        )}
        {isLoading && (
          <CircularProgress size={16} className="text-white ml-2" />
        )}
      </Button>
    </div>
  );
};

export default LoadMoreButton;
