import styled from "styled-components";
import { Zoom } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { useState } from "react";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    enterTouchDelay={0}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--light-black)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--light-black)",
    fontSize: "0.8rem",
    padding: "0.625rem",
    borderRadius: "0.5rem",
  },
}));

type Props = {
  title: string;
};

const CustomTooltip = ({ title }: Props) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <span className="flex items-center cursor-pointer">
      <StyledTooltip
        title={title}
        TransitionComponent={Zoom}
        disableHoverListener
        open={show}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <Info onClick={toggleShow} />
      </StyledTooltip>
    </span>
  );
};

export default CustomTooltip;
