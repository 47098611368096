import { Routes, Route, Navigate } from "react-router-dom";
import Login from "pages/login";
import Register from "pages/register";
import ForgotPassword from "pages/forgot-password";
import ResetPassword from "pages/reset-password";
import CompleteProfile from "pages/complete-profile";
import Page404 from "pages/404";
import Home from "pages/home";
import Applications from "pages/applications";
import ApplicationDetails from "pages/applications-details";
import NetworkDetails from "pages/network-details";
import Profile from "pages/profile";
import Network from "pages/network";
import GlobalSearch from "pages/global-search";
import SwitchToAdminMode from "pages/Switch-to-admin-mode";
import PageLayout from "layouts/PageLayout";
import AuthLayout from "layouts/AuthLayout";
import { getToken } from "helpers/storage";
import { ROUTES } from "./routes";
import APPLICATION_DETAILS_ROUTES from "modules/application-details/APPLICATION_DETAILS_ROUTES";
import CONTACT_DETAILS_ROUTES from "modules/network-details/CONTACT_DETAILS_ROUTES";
import GLOBAL_SEARCH_ROUTES from "modules/global-search/GLOBAL_SEARCH_ROUTES";
import NETWORK_ROUTES from "modules/network/NETWORK_ROUTES";

function AppRouter() {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.APPLICATIONS} element={<Applications />} />
        <Route
          path={ROUTES.APPLICATION_DETAILS}
          element={<ApplicationDetails />}
        >
          {APPLICATION_DETAILS_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={APPLICATION_DETAILS_ROUTES[0].id} />}
          />
        </Route>
        <Route
          path={ROUTES.RECOMMENDED_APPLICATION_DETAILS}
          element={<ApplicationDetails />}
        >
          {APPLICATION_DETAILS_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={APPLICATION_DETAILS_ROUTES[0].id} />}
          />
        </Route>
        <Route path={ROUTES.PROFILE} element={<Profile />} />
        <Route path={ROUTES.NETWORK} element={<Network />}>
          {NETWORK_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={NETWORK_ROUTES[0].id} />}
          />
        </Route>
        {/* <Route path={ROUTES.NETWORK_RECOMMENDED} element={<Network />}>
          {NETWORK_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={NETWORK_ROUTES[0].id} />}
          />
        </Route> */}

        <Route
          path={ROUTES.NETWORK_DETAILS + "/:id"}
          element={<NetworkDetails />}
        >
          {CONTACT_DETAILS_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={CONTACT_DETAILS_ROUTES[0].id} />}
          />
        </Route>
        <Route
          path={ROUTES.RECOMMENDED_NETWORK_DETAILS + "/:id"}
          element={<NetworkDetails />}
        >
          {CONTACT_DETAILS_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={CONTACT_DETAILS_ROUTES[0].id} />}
          />
        </Route>
        <Route path={ROUTES.GLOBAL_SEARCH} element={<GlobalSearch />}>
          {GLOBAL_SEARCH_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={GLOBAL_SEARCH_ROUTES[0].id} />}
          />
        </Route>
      </Route>

      <Route element={<AuthLayout />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.COMPLETE_PROFILE} element={<CompleteProfile />} />
      </Route>
      <Route path={ROUTES.NOT_FOUND} element={<Page404 />} />
      <Route
        path={ROUTES.SWITCH_TO_ADMIN_MODE}
        element={<SwitchToAdminMode />}
      />
      <Route path="" element={<Navigate to={ROUTES.HOME} />} />
      <Route path="*" element={<Navigate replace to={ROUTES.NOT_FOUND} />} />
      <Route
        index
        element={
          getToken() ? (
            <Navigate to={ROUTES.HOME} />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
    </Routes>
  );
}

export default AppRouter;
