import { Navigate, Outlet } from "react-router-dom";
import AuthHeader from "components/header/AuthHeader";
import { getToken } from "helpers/storage";
import { ROUTES } from "app-router/routes";

function AuthLayout() {
  const isLoggedIn = getToken();
  return (
    <>
      <AuthHeader />
      {isLoggedIn ? <Navigate to={ROUTES.HOME} /> : <Outlet />}
    </>
  );
}

export default AuthLayout;
