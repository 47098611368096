import { useMemo } from "react";
import styled from "styled-components";
import { APPLICATION_STATUS } from "consts/APPLICATION_STATUS";

type Props = {
  status?: string;
};

const StyledBadge = styled.div<{ bg: string; color: string }>`
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
`;

const StatusBadge = ({ status }: Props) => {
  const [bg, color, label] = useMemo(() => {
    const statuses = [...APPLICATION_STATUS];
    let background = APPLICATION_STATUS[0].badgeBg;
    let color = APPLICATION_STATUS[0].badgeColor;
    let label = status;
    const found = statuses.find((item) => item.value === status);
    if (found) {
      background = found.badgeBg;
      color = found.badgeColor;
      label = found.label;
    }
    return [background, color, label];
  }, [status]);

  return (
    <StyledBadge
      className="status-badge h-fit text-xs rounded px-2 py-[6px]"
      bg={bg}
      color={color}
    >
      <span>{label}</span>
    </StyledBadge>
  );
};

export default StatusBadge;
