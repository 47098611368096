import { twMerge } from "tailwind-merge";

const CharactersCount = ({
  maxCharacters,
  charactersCount,
  className,
}: {
  maxCharacters: number;
  charactersCount: number;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "absolute right-0 bottom-0 flex justify-end text-sm dim-text",
        className
      )}
    >
      {`${charactersCount}/${maxCharacters}`}
    </div>
  );
};

export default CharactersCount;
