export const ACTIVITY_TYPES = [
  {
    label: "Call",
    value: "Call",
  },
  {
    label: "Coffee chat",
    value: "Coffee chat",
  },
  {
    label: "Interview",
    value: "Interview",
  },
  {
    label: "Other",
    value: "Other",
  },
];
