import EmptyState from "assets/images/empty_state.png";
import { twMerge } from "tailwind-merge";

type Props = {
  containerClassName?: string;
  imgClassName?: string;
  noDataText?: string;
};

const NoDataFound = ({
  containerClassName,
  imgClassName,
  noDataText = "No Data Found",
}: Props) => {
  return (
    <div
      className={twMerge(
        "flex flex-col items-center justify-center h-full",
        containerClassName
      )}
    >
      <img
        src={EmptyState}
        alt="empty state"
        className={twMerge("w-96", imgClassName)}
      />
      <div className="text-[#1D1E1B] font-bold mt-2">{noDataText}</div>
    </div>
  );
};

export default NoDataFound;
