import { useMemo } from "react";
import { Grid } from "@mui/material";
import CustomProgressbar from "components/CustomProgressbar";
import SuggestedReachouts from "./components/SuggestedReachouts";
import SuggestedTasks from "./components/SuggestedTasks";
import { useApplicationDetails } from "context/ApplicationsDetailsContext";
import { APPLICATION_STATUS } from "consts/APPLICATION_STATUS";

const Progress = () => {
  const { application_details } = useApplicationDetails();

  const progressPercentage = useMemo(() => {
    if (
      application_details?.application_status === "ARCHIVED_REJECTED" ||
      application_details?.is_deleted
    )
      return 0;

    const findIndex = APPLICATION_STATUS.findIndex(
      (i: { value: string; label: string }) =>
        i.value === application_details?.application_status
    );
    return (findIndex + 1) * 25;
  }, [
    application_details?.application_status,
    application_details?.is_deleted,
  ]);

  return (
    <div>
      <CustomProgressbar variant="determinate" value={progressPercentage} />
      <Grid container spacing={4} className="mt-0">
        <Grid item xs={12} md={12}>
          <SuggestedTasks />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <SuggestedReachouts />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Progress;
