import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import { EditSuggestedTaskTypes } from "helpers/types/suggested.types";
import { EditInterviewQuestionTypes } from "helpers/types/interviewQuestions.types";

const questions = {
  getInterviewQuestions(application_user_id: string) {
    return apiClient
      .post(API.MANAGE_QUESTIONS, {
        action: "get_question",
        application_user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  editInterviewQuestions(payload: EditInterviewQuestionTypes) {
    return apiClient
      .post(API.MANAGE_QUESTIONS, {
        action: "edit_question",
        is_answered: 1,
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteInterviewQuestions(payload: EditInterviewQuestionTypes) {
    return apiClient
      .post(API.MANAGE_QUESTIONS, {
        action: "delete_question",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  getQuetsionAnswer(payload: EditSuggestedTaskTypes) {
    return apiClient
      .post(API.MANAGE_QUESTIONS, {
        action: "get_answer",
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default questions;
