import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import CompanyLogo from "components/CompanyLogo";
import Loader from "components/loaders/Loader";
import StatusBadge from "./StatusBadge";
import applications from "helpers/http/application";
import { CommonResponseType } from "helpers/types/response.types";
import { formatJobType, isEmptyObject, showError } from "helpers/misc";
import {
  GetApplicationsListResponseTypes,
  GetRecommendedResponseTypes,
  SaveUnsaveApplicationTypes,
  UpdateApplicationStatusTypes,
} from "helpers/types/applications.types";
import { useApplications } from "context/ApplicationsContext";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as CompanyIcon } from "assets/icons/buliding.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";

type Props = {
  item: GetRecommendedResponseTypes | GetApplicationsListResponseTypes;
  type?: "default" | "global-search";
};

interface CloneApplicationResponseType extends CommonResponseType {
  data: {
    application_user_id: string;
  };
}

const ApplicationCard = ({ item, type = "default" }: Props) => {
  const { selectedFilters } = useApplications();
  const [isSaved, setIsSaved] = useState<boolean>(Boolean(item.is_saved));
  const [applicationUserId, setApplicationUserId] = useState<string>("");

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    CloneApplicationResponseType,
    string,
    SaveUnsaveApplicationTypes
  >((data) => applications.saveUnsaveApplication(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        queryClient.invalidateQueries(["get-recommended-aplications"]);
        queryClient.invalidateQueries(["get-applications-list"]);
        queryClient.invalidateQueries(["get-application-list-count"]);

        if (res?.data?.application_user_id) {
          setApplicationUserId(res?.data?.application_user_id);
        }
        setIsSaved((prev) => !prev);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const { isLoading: isChangingStatus, mutate: changeStatus } = useMutation<
    CommonResponseType,
    string,
    UpdateApplicationStatusTypes
  >((data) => applications.changeStatus(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        queryClient.invalidateQueries(["get-recommended-aplications"]);
        queryClient.invalidateQueries(["get-applications-list"]);
        queryClient.invalidateQueries(["get-application-list-count"]);
        setIsSaved((prev) => !prev);
      } else {
        showError(res?.message);
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const toggleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if ("application_id" in item) {
      mutate({
        application_id: item.application_id,
        status: isSaved ? "unsave" : "save",
      });
    } else {
      changeStatus({
        application_user_id: item.application_user_id,
        status: isSaved ? "UNSAVE" : "SAVE",
      });
    }
  };

  const companyLocation = useMemo(() => {
    const formattedJobTypes = item.job_type?.map((i) => formatJobType(i));
    const jobTypes = formattedJobTypes?.join(" | ");
    let location = "";
    if (!isEmptyObject(item.location)) {
      if (item.location?.address) {
        location = item.location?.address;
      } else {
        location = [
          item.location.city,
          item.location.state,
          item.location.country,
        ]
          .filter(Boolean)
          .join(", ");
      }
      return `${location} ${jobTypes && `(${jobTypes})`}`;
    } else {
      return jobTypes;
    }
  }, [item.job_type, item.location]);

  const itemUrl = useMemo(() => {
    let url = "";
    if ("application_user_id" in item && item.application_user_id) {
      let id = item.application_user_id;
      url = `/application-details/${id}`;
    } else if ("application_id" in item && item.application_id) {
      let id = item.application_id;
      url = `/application-details/recommended/${id}`;
    }
    if (applicationUserId) {
      url = `/application-details/${applicationUserId}`;
    }
    // if (selectedFilters.applicationFilter === "browse" && type === "default") {
    //   url = `/application-details/recommended/${id}`;
    // }

    return url;
  }, [applicationUserId, item]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  return (
    <Link
      to={itemUrl}
      onClick={!itemUrl ? handleClick : undefined}
      className="h-full group flex bg-white p-5 rounded-lg gap-4 cursor-pointer transition ease-in-out duration-300 hover:shadow-[0px_17px_59px_0px_rgba(43,53,80,0.15)] no-underline"
    >
      <CompanyLogo url={item.job_url || ""} />

      <div className="flex-1">
        <div className="text-lightblack font-semibold transition ease-in-out duration-300 capitalize">
          {item.title}
        </div>
        <div className="flex text-darkGray text-sm mt-2 capitalize gap-2">
          <span className="flex items-center">
            <CompanyIcon className="h-4 w-4" />
          </span>
          {item.company_name}
        </div>
        {!!companyLocation && (
          <div className="flex text-darkGray text-sm mt-2 gap-2">
            <span>
              <LocationIcon className="stroke-darkGray h-4 w-4" />
            </span>
            {companyLocation}
          </div>
        )}
      </div>
      {type === "default" &&
        selectedFilters.applicationFilter !== "archived_rejected" &&
        selectedFilters.applicationFilter !== "archived_deleted" && (
          <span>
            {!isLoading && !isChangingStatus && (
              <IconButton className="-mt-2" onClick={toggleSave}>
                <SaveIcon
                  className={classNames("", {
                    "stroke-[#444]": !isSaved,
                    "stroke-primary-color fill-primary-color": isSaved,
                  })}
                />
              </IconButton>
            )}
            {(isLoading || isChangingStatus) && (
              <Loader size={20} className="mr-3" />
            )}
          </span>
        )}
      {type === "global-search" && item.application_status && (
        <StatusBadge status={item.application_status} />
      )}
    </Link>
  );
};

export default ApplicationCard;
