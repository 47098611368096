import { ROUTES } from "app-router/routes";
import axios, { AxiosError, AxiosResponse } from "axios";
import { showError } from "helpers/misc";
import { getProfileToken, getToken } from "helpers/storage";

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error.response?.status === 401) {
    await localStorage.removeItem("token");
    await localStorage.removeItem("profile_token");
    showError("Session expired. Please login again.");
    window.location.replace(ROUTES.LOGIN);
  }
  return Promise.reject(error);
};

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    } else {
      /** After registration, the token is stored in profile_token.
       * Making it separate than the actual token so that routing can be managed based on token
       * */
      const profileToken = getProfileToken();
      if (profileToken) {
        config.headers["Authorization"] = "bearer " + profileToken;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(onResponse, onResponseError);

export default apiClient;
