import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Zoom } from "@mui/material";
import styled from "styled-components";
import classNames from "classnames";
import MenuIcon from "@mui/icons-material/Menu";
import SearchBox from "components/SearchBox";
import NavItem from "./NavLink";
import Sidebar from "./Sidebar";
import ProfileMenu from "./ProfileMenu";
import LogoutPrompt from "./LogoutPrompt";
import { breakpoints } from "helpers/hooks/useResponsive";
import { useAuth } from "context/AuthContext";
import { NAVS } from "consts/NAVS";
import ProfileAvatar from "components/ProfileAvatar";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { Button } from "components/buttons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 5.635rem;
  @media ${breakpoints.mobile} {
    padding: 24px;
  }
`;
const Header = () => {
  const {
    user,
    signout,
    handleGlobalSearch,
    globalSearch,
    globalSearchResultsData,
  } = useAuth();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const [showLogoutPrompt, setShowLogoutPrompt] = useState<boolean>(false);
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const toggleProfileMenu = () => setOpenProfileMenu(!openProfileMenu);
  const toggleLogoutPrompt = () => {
    setShowLogoutPrompt((prev) => !prev);
  };

  const onLogout = () => {
    toggleLogoutPrompt();
    signout();
  };

  const toggleSearchBox = () => setShowSearchBox((prev) => !prev);

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onGlobalSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleGlobalSearch(searchValue);
  };

  useEffect(() => {
    setSearchValue(globalSearch);
  }, [globalSearch]);

  return (
    <>
      <Wrapper className="md:gap-8 gap-4 md:px-10 px-4">
        <Zoom in>
          <Link to="/" className="flex items-center">
            <img
              src="/logo.svg"
              alt="jackfruit-logo"
              className="logo cursor-pointer"
            />
          </Link>
        </Zoom>

        <div className="md:flex hidden items-center gap-8">
          <div className="divider h-5 w-[1.5px] bg-lightgray" />

          {NAVS.map((nav) => (
            <NavItem nav={nav} key={nav.name} />
          ))}
        </div>

        <div className="flex items-center gap-4 ml-auto">
          <form onSubmit={onGlobalSearch}>
            <SearchBox
              placeholder="Search"
              className="md:block hidden"
              onChange={onChangeText}
              value={searchValue}
              // showSearchButton={!!searchValue}
              endIcon={
                <Button
                  type="submit"
                  className="rounded-none rounded-r-lg hover:transform-none hover:shadow-none h-full transition ease-in-out duration-300"
                  disabled={globalSearchResultsData?.isLoading}
                >
                  Search
                </Button>
              }
              isLoading={globalSearchResultsData?.isLoading}
            />
          </form>
          <div
            onClick={toggleProfileMenu}
            className="relative md:flex hidden items-center p-[2px] rounded-full w-[80px] border border-solid border-lightgray hover:border-lightblack transition ease-in-out duration-300 cursor-pointer"
          >
            <ProfileAvatar
              imageurl={user?.user_image}
              className="h-11 w-11 rounded-full object-cover"
              name={user ? user?.first_name + " " + user?.last_name : ""}
            />
            <ArrowDown className="m-auto" />
            {openProfileMenu && (
              <ProfileMenu
                toggle={toggleProfileMenu}
                onLogout={toggleLogoutPrompt}
                is_admin={user?.is_admin}
              />
            )}
          </div>
        </div>

        <LogoutPrompt
          isOpen={showLogoutPrompt}
          toggle={toggleLogoutPrompt}
          logout={onLogout}
        />
        <div className="ml-auto flex items-center gap-3 md:hidden">
          <SearchIcon
            className="cursor-pointer md:hidden block"
            onClick={toggleSearchBox}
          />
          <IconButton onClick={toggleDrawer}>
            <MenuIcon className="cursor-pointer" />
          </IconButton>
        </div>
      </Wrapper>
      <form onSubmit={onGlobalSearch}>
        <SearchBox
          placeholder="Search"
          onChange={onChangeText}
          value={searchValue}
          // showSearchButton={!!searchValue}
          isLoading={globalSearchResultsData?.isLoading}
          className={classNames(
            "md:hidden mx-6 transition ease-in-out duration-300",
            {
              "max-h-0 overflow-hidden transition ease-in-out duration-300":
                !showSearchBox,
              "max-h-full mb-4 transition ease-in-out duration-300":
                showSearchBox,
            }
          )}
          endIcon={
            <Button
              type="submit"
              className="rounded-none rounded-r-lg hover:transform-none hover:shadow-none h-full transition ease-in-out duration-300"
              disabled={globalSearchResultsData?.isLoading}
            >
              Search
            </Button>
          }
        />
      </form>

      <div className="md:hidden">
        <Sidebar
          isOpen={openDrawer}
          toggleDrawer={toggleDrawer}
          onLogout={toggleLogoutPrompt}
        />
      </div>
    </>
  );
};

export default Header;
