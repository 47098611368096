import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "components/buttons";
import Stepper from "components/Stepper";
import ImportLinkedinContactsSteps from "components/ImportLinkedinContactsSteps";
import UploadCsv from "components/UploadCsv";
import contactsApi from "helpers/http/contacts";
import { useAuth } from "context/AuthContext";
import { createChunks, parseCSVRow, showError, showMsg } from "helpers/misc";
import { CommonResponseType } from "helpers/types/response.types";
import { ContactType, UploadContactRequestType } from "helpers/types/contacts";

const NoContactsState = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [contactsFile, setContactsFile] = useState<File | null>(null);
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const [chunks, setChunks] = useState<ContactType[][]>([]); // [[], [], []

  // const onContactsChange = async (data: {
  //   contacts: ContactType[];
  //   file: File | null;
  // }) => {
  //   setContacts(data.contacts);
  //   const chunks = await createChunks(data?.contacts, 50);
  //   setChunks(chunks);
  //   setContactsFile(data.file);
  // };

  const onContactsChange = async (data: {
    contacts: ContactType[];
    file: File | null;
  }) => {
    const reader = new FileReader();

    reader.onload = async function (e: any) {
      const csvContent = e.target.result;
      const rows = csvContent.split("\n");
      const headers = parseCSVRow(rows[3]);
      const csvData: any = {
        columns: headers,
        rows: [],
      };

      for (let i = 4; i < rows.length; i++) {
        const values = parseCSVRow(rows[i]);
        const row: any = {};

        for (let j = 0; j < headers.length; j++) {
          row[headers[j]] = values[j];
        }

        csvData.rows.push(row);
      }
      console.log("csvData: ", csvData);

      const contactsArray: ContactType[] = csvData.rows.map((i: any) => {
        return {
          name: i["First Name"]?.trim() + " " + i["Last Name"]?.trim(),
          email: i["Email Address"]?.trim(),
          company: i["Company"]?.trim(),
          role: i["Position"]?.trim(),
          url: i["URL"]?.trim(),
        };
      });
      const filteredArray = contactsArray?.filter(
        (i: ContactType) =>
          i.name !== " " &&
          i.name !== "" &&
          i.name !== undefined &&
          i.name !== "undefined" &&
          i.name !== " undefined"
      );

      console.log("contactsArray: ", filteredArray);
      const chunks = await createChunks(filteredArray, 50);
      setChunks(chunks);
      setContacts(filteredArray);
      setContactsFile(data.file);
    };

    data?.file !== null && reader.readAsText(data?.file);
  };

  const { isLoading, mutate: uploadContacts } = useMutation<
    CommonResponseType,
    string,
    UploadContactRequestType
  >((data) => contactsApi.uploadContacts(data), {
    onSuccess: async (res) => {
      if (res?.status) {
        const currentChunks = [...chunks];
        if (currentChunks?.length > 1) {
          currentChunks.shift();
          setChunks(currentChunks);
          uploadContacts({
            contactData: currentChunks[0],
          });
        } else {
          queryClient.invalidateQueries(["get-your-contacts"]);
          showMsg(res?.message);
        }
      }
    },
    onError: (err) => {
      showError(err);
    },
  });

  const onNext = () => {
    if (activeStep < 3) {
      setActiveStep((prev) => prev + 1);
    } else {
      if (contacts?.length > 0) {
        uploadContacts({
          contactData: chunks[0],
        });
      } else {
        showError("Please upload a CSV file");
      }
    }
  };

  return (
    <div className="no-contacts-state flex flex-col items-center justify-center w-full py-20 px-4">
      <div className="w-[620px] max-w-full m-auto flex flex-col items-center justify-center">
        <Stepper activeStep={activeStep} color="primary" />
      </div>
      {activeStep === 0 && <Step1 user_name={user?.first_name} />}
      {activeStep === 1 && <Step2 />}
      {activeStep === 2 && <Step3 user_name={user?.first_name} />}
      {activeStep === 3 && (
        <Step4
          contactsFile={contactsFile}
          onContactsChange={onContactsChange}
          user_name={user?.first_name}
        />
      )}
      <LoadingButton onClick={onNext} className="mt-4" loading={isLoading}>
        {activeStep === 3 ? "Import" : "Continue"}
      </LoadingButton>
    </div>
  );
};

export default NoContactsState;

const Step1 = ({ user_name }: { user_name?: string }) => {
  return (
    <div className="text-center w-[620px] max-w-full m-auto flex flex-col items-center justify-center">
      <div className="mt-12 text-xl font-semibold">
        Hey <span className="capitalize">{user_name}</span>, welcome to the
        world of jackfruit!
      </div>
      <p className="text-xl text-lightblack leading-relaxed">
        Bring in your LinkedIn contacts and let jackfruit work its magic to help
        you conquer your career aspirations. Get ready to ride the wave of
        success and land your dream job! 🚀
      </p>
    </div>
  );
};

const Step2 = () => {
  return (
    <div className="w-[620px] max-w-full text-center text-xl text-lightblack font-medium leading-relaxed mt-12 mb-5">
      To bring your LinkedIn contacts into jackfruit, you'll need to export them
      from LinkedIn first. Don't sweat it if you're not sure how! We've got you
      covered just hit the button below and we’ll hook you up with easy-peasy
      steps to export your connections from LinkedIn. Let's make this happen
      together! 💪📤
    </div>
  );
};

const Step3 = ({ user_name }: { user_name?: string }) => {
  return (
    <div className="text-center max-w-full">
      <div className="mt-12 text-xl font-semibold w-[658px] max-w-full m-auto">
        Ready for some LinkedIn wizardry,{" "}
        <span className="capitalize">{user_name}</span>? Here's your easy-peasy
        roadmap to exporting your connections like a pro:
      </div>
      <div className="text-start bg-white p-10 w-[800px] max-w-full my-8 rounded-xl">
        <ImportLinkedinContactsSteps />
      </div>
      <div className="text-xl font-semibold w-[658px] max-w-full m-auto mb-4">
        Voila! You're now equipped with the power of connection exportation.
        Let's take your jackfruit journey to new heights! 🚀🔥
      </div>
    </div>
  );
};

const Step4 = ({
  contactsFile,
  onContactsChange,
  user_name,
}: {
  contactsFile: File | null;
  onContactsChange: (data: {
    contacts: ContactType[];
    file: File | null;
  }) => void;
  user_name?: string;
}) => {
  return (
    <div className="text-center max-w-full">
      <div className="mt-12 text-xl font-semibold w-[658px] max-w-full m-auto">
        Almost there, <span className="capitalize">{user_name}</span>! Time to
        unleash the power of your LinkedIn connections. Just upload the CSV file
        and hit ‘Import’ button. Let the magic commence! ✨🚀
      </div>
      <div className="p-9 bg-white rounded-lg mt-8 mb-4">
        <UploadCsv
          contactsFile={contactsFile}
          onContactsChange={onContactsChange}
        />
      </div>
    </div>
  );
};
