import { ROUTES } from "app-router/routes";
import { AxiosResponse } from "axios";
import { showError } from "helpers/misc";

export const responseResolver = (response: AxiosResponse) => {
  if (response.data?.status === false) {
    return Promise.reject(response.data?.message || response.data?.response);
  }
  return response.data;
};
export const errorResolver = async (error: {
  response: { data: { message: string; error_code?: string } };
}) => {
  if (error.response?.data?.error_code === "USER_REMOVED") {
    showError(error.response?.data?.message);
    await localStorage.removeItem("token");
    await localStorage.removeItem("profile_token");
    window.location.replace(ROUTES.LOGIN);
    return Promise.reject(error?.response?.data?.message || error?.toString());
  } else {
    return Promise.reject(error?.response?.data?.message || error?.toString());
  }
};
